.plan{
  background: #fff;
  border: 1px solid #bfe6e7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 20px);
  justify-content: center;
  margin: 10px auto;
  min-height: 160px;
  padding: 10px 30px;
  position: relative;
  transition: all .5s ease;
  -webkit-transition: all .5s ease;
  width: 320px;
  max-width: 100%;
}
.plan:hover{
  box-shadow: 6px 6px 20px #bfe6e7;
}
.selected{
  box-shadow: 6px 6px 20px #bfe6e7;
}