.infoWrapper{
  margin-bottom: 2em;
}
.infoRow{
  padding: 5px;
}
.comment{
  padding: 1em;
  border: 1px solid #999;
  margin: 1em;
  border-radius: 5px;
}
.comment .date{
  font-size: 0.7em;
  text-align: right;
}
.recom{
  padding: 1em;
  border: 1px solid #999;
  margin: 10px;
  position: relative;
}

.revoked{
  border: 2px solid red;
}
.revoked::after{
  color: red;
  content: "REVOKED";
  font-size: 50px;
  left: 25%;
  opacity: .4;
  position: absolute;
  rotate: -12deg;
  top: 40px;
  z-index: 1;
}
.caregiver {
  padding: 1em;
  border-top: 1px solid #333;
  margin-top: 1em;
}
.resize textarea{
  resize: vertical;
}

.messagesList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.messageItem {
  max-width: 80%;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
}

.messageItem.sent {
  background-color: #e3f2fd;
  align-self: flex-end;
  border-bottom-right-radius: 2px;
}

.messageItem.received {
  background-color: #f5f5f5;
  align-self: flex-start;
  border-bottom-left-radius: 2px;
}

.messageContent {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.messageContent p {
  margin: 0;
  word-break: break-word;
}

.messageContent small {
  color: #666;
  font-size: 0.8em;
  align-self: flex-end;
}

.chatHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eee;
}

.chatHeader h4 {
  margin: 0;
  color: #333;
}