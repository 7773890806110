.pricing {
    padding: 20px 45px 50px;
}
.price_listing .p_ulist {
    background: #FFFFFF;
    border: 1px solid #BFE6E7;
    border-radius: 10px;
    padding: 20px;
    margin: 0;
}
.price_listing .p_ulist .p_list {list-style: none !important;position: relative;padding: 10px 0 10px 30px !important;}

.price_listing .p_ulist .p_list img {position: absolute;left: 0;top: 12px;}

.price_listing .p_ulist .p_list ul {padding: 0;margin: 10px 0 0;list-style: none;}

.price_listing .p_ulist .p_list ul li {font-size: 1em; line-height: 18px; color: #244142;position: relative;padding: 0 0 0 20px;}

.price_listing .p_ulist .p_list ul li:before {position: absolute;content: "";width: 8px;height: 8px;border-radius: 30px;background: linear-gradient(0deg, #BFE6E7, #BFE6E7), #BFE6E7;left: 0;top: 4px;}

#pricing li.p_list p {
    font-weight: 500;
    font-size: 1.1em;
    color: #244142;
    margin: 0;
}
#pricing .pricing-plan-bx {
    height: 100%;
}
#pricing .pricing_wrapper {
    padding: 10px 30px 10px 30px;
    transition: all ease 0.5s;
    -webkit-transition: all ease 0.5s;
    background: #FFFFFF;
    border: 1px solid #BFE6E7;
    border-radius: 10px;
    min-height: 160px;
    position: relative;
    margin: 10px 0;
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#pricing .pricing_wrapper .control__indicator {
    top: 6px;
    left: 0;
    background: #BFE6E7;
    border-radius: 5px;
    border: 0;
    position: relative;
}
.price-dollar {
    padding-left: 10px;
}
.text {
    padding-left: 30px;
}
#pricing .pricing-plan-bx input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  opacity: 0;
}
#pricing .pricing_wrapper h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #244142;
	transition: all ease 0.5s;
	-webkit-transition: all ease 0.5s;
}
#pricing .price-dollar .p-small {
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    font-weight: 400;
}
#pricing .pricing_wrapper p {
    font-size: 12px;
    line-height: 18px;
    color: #244142;
}
#pricing .pricing_wrapper p.color-red {
    color: #FF0000;
}
#pricing .pricing_wrapper p:last-child {
  margin: 0;
  padding: 0;
}
#pricing .pricing-plan-bx .control {
    padding: 0;
    margin: 0;
    height: 100%;
}
.control input:checked ~ .pricing_wrapper .control__indicator:after {
    display: block;
}
#pricing .pricing_wrapper:hover, #pricing input:checked + .pricing_wrapper {
    box-shadow: 6px 6px 20px #bfe6e7;
}
#pricing .control input:checked ~ .pricing_wrapper .control__indicator {
    background: #23A5A7;
}


#pricing .pricing_wrapper:hover h2,
#pricing input:checked + .pricing_wrapper h2 {
  color: #23A5A7;
}

#pricing p:last-child {
    margin: 0;
}

#pricing .id_card, 
#pricing .address_form {
    padding: 20px;
    box-shadow: 0px 2px 5px #e8e6e6;
    background: #fff;
}
#pricing #upload {
    padding: 8px 25px;
    font-weight: 500;
    font-size: 16px;
    height: 65px;
    width: 100%;
}




#pricing .payment_info .address_box .heading {
    min-height: 135px;
}
#pricing .payment_info h5 {
    color: #3cb7b4;
    font-size: 18px;
}
#pricing .payment_info .billing_box_1 .heading {
    position: relative;
}

#pricing .payment_info .billing_box_1 .heading button {
    position: absolute;
    right: 20px;
    background: #3cb7b4;
    color: #fff;
    bottom: 20px;
    border-radius: 30px;
    padding: 8px 12px;
}
#pricing .payment_info .billing_box_1 p {
    margin: 0;
    padding-right: 125px;
}
#pricing .payment_info .order-table tbody tr:nth-of-type(odd) {
    background: transparent;
}

#pricing .payment_info .order-table td:last-child {
    text-align: right;
    padding-right: 35px;
    position: relative;
}
#pricing .payment_info .order-table td:last-child button {
    color: #fff;
    border: 0;
    background: red;
    font-size: 11px;
    border-radius: 510%;
    height: 18px;
    position: absolute;
    right: 5px;
    font-weight: 900;
    top: 14px;
    width: 18px;
    text-align: center;
    line-height: 18px;
    padding: 0;
}
#pricing .payment_info .order-table tbody tr:nth-last-of-type(1){
    background: #3cb7b4;
}

#pricing .payment_info .order-table tbody tr:nth-last-of-type(1) td {color: #fff;}

#pricing .coupon_details .applycoupon {
    background: #3cb7b4;
    color: #fff;
    border-radius: 30px;
    border: 0;
    padding: 10px 15px;
    font-weight: 500;
    width: 100%;
    position: relative;
    bottom: -5px;
}

#pricing .modal .modal-dialog {
    max-width: 700px;
}

#pricing .modal .modal-dialog .modal-content {
    background: transparent;
    border: 0;
    position: relative;
}

#pricing .modal .modal-dialog .modal-content .close {
    position: absolute;
    right: 5px;
    top: 5px;
    background: #3cb7b4;
    width: 30px;
    height: 30px;
    z-index: 2;
    opacity: 1;
    border-radius: 50%;
    padding: 0;
    color: #fff;
    font-size: 26px;
}

#pricing .card_text span{
	color:red;
}
#pricing .main-label {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
}
.coupon_box input.form-control {
    background: #FFFFFF;
    border: 1px solid rgba(196, 196, 196, 0.7);
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    padding: 0 30px;
}
.coupon_box .applycoupon {
    background: #BFE6E7;
    border-radius: 5px;
    height: 65px;
    border: 0;
    font-size: 16px;
    line-height: 24px;
    color: #23A5A7;
    width: 100%;
    font-weight: 700;
}

.patient_id .card_text h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #244142;
    margin: 0;
}


.radio_boxes .radio_box input[type="radio"]:checked + span + span{
	background: linear-gradient(0deg, #23A5A7, #23A5A7), #23A5A7;
}
.radio_boxes .label_text {
    font-size: 1em;
    color: #232323;
}
.radio_boxes .label_text span {
    color: #58AB68;
    display: inline-block;
    margin: 0 0 0 10px;
}
.radio_boxes span.checkmark {
    position: absolute;
    left: 0;
    background: linear-gradient(0deg, #BFE6E7, #BFE6E7), #BFE6E7;
    width: 20px;
    height: 20px;
    border-radius: 30px;
    top: 2px;
}
.radio_boxes span.checkmark:before {
    content: '\2022';
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
    width: 20px;
    height: 20px;
	opacity: 0;
}
.radio_boxes .radio_box input[type="radio"]:checked + span + span.checkmark:before{
	opacity: 1;
}

.radio_boxes .radio_box {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0 0 0 35px;
    font-size: 1rem;
    /* border-right: 1px solid #212529; */
    margin: 0 50px 0 0;
    display: inline-block;
}

/* Hide the browser's default radio button */
.radio_boxes .radio_box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
#pricing .back-btn {
    margin-right: 10px;
    color: #23A5A7;
}

section#pricing {position: relative;}
.order_block h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
}
.order_block.pricing_info {
    position: fixed;
    top: 0;
    max-width: 290px;
    width: 100%;
    padding: 45px 0 0 0;
    right: 50px;
}

.order_box th, .order_box td {padding: 0;background: transparent;border: 0;font-size: 14px;height: 35px;}

.order_box .order-table tbody tr:nth-of-type(odd) {background: transparent;}

.order_box th {font-weight: 500;}

.order_box .order-table tbody tr:last-child {border-top: 1px solid #23A5A7;}

.order_box .order-table tbody tr:last-child td {padding: 10px 0 0;color: #23A5A7;font-weight: 500;}

.order_box .order-table {
    margin: 45px 0;
    width: 100%;
}


.shipping_box p {font-size: 14px;
line-height: 21px;

color: #244142;margin: 20px 0 25px;}

.shipping_box button {background: #23A5A7;
border-radius: 7px;height: 30px;padding: 0 25px;font-weight: 700;
font-size: 12px;
line-height: 18px;color: #FFFFFF;}


.file-upload {
    border: 1px solid rgba(196, 196, 196, 0.7);
    box-sizing: border-box;
    border-radius: 10px;
    height: 65px;
    color: #232323;
    padding: 0 25px;
}

.file-upload-content {
    display: none;
    text-align: center;
    height: 100%;
}
.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-dropping {
  background-color: rgba(60, 183, 180, 0.1);
  border: 4px dashed #ccc;
}
.drag-text button {
    border: 0;
    height: 65px;
    color: #232323;
    display: block;
    background: transparent;
}
.upload_cont {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    overflow: hidden;
}
.file-upload-image {
    width: 100%;
    max-width: 200px;
}
.remove-image {
    margin: 0;
    color: #fff;
    background: #cd4535;
    border: none;
    padding: 8px;
    border-radius: 10px;
    outline: none;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10px;
    display: flex;
    align-items: center;
}
.remove-image img {
    filter: brightness(10);
	-webkit-filter: brightness(10);
    margin-right: 10px;
}
@media screen and (max-width: 1199px){
	.order_block.pricing_info {
		position: static;
		padding: 45px 0;
		margin-left: 0;
	}
}

@media screen and (max-width: 767px){
	.pricing {
		padding: 20px 25px 50px;
	}
}

#pricing .page-listing ul li::before{
    display: none;
}

#dashboard #pricing li{
    border: none !important;
    padding: 0 16px;
}

#pricing{
    text-align: left !important;
    padding: 1em !important;
}
.pricing-bx{
    font-size: 1.5em;;
}