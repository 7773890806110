#dashboard .page-container {
    min-height: 100%;
    background: #fff;
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
#dashboard .page-container:not(.container) {
    width: 100%;
}
#dashboard .page-container.container {
    padding-left: 0;
    padding-right: 0;
}

.page-header-fixed #dashboard .page-header {
    position: fixed;
    z-index: 99;
    left: 261px;
    right: 0;
    top: 0;
    width: calc(100% - 261px);
}
#dashboard .navbar {
    padding: 10px 15px !important;
}
#dashboard .page-header .navbar {
    padding: 0 !important;
}
.page-header-fixed #dashboard .secondary-sidebar-bar {
    position: fixed;
    background: #fff !important;
    width: 100%;
}
.page-header-fixed.collapsed-sidebar #dashboard .page-header {
    left: 122px;
    width: calc(100% - 122px);
}
.page-header-fixed.collapsed-sidebar #dashboard .secondary-sidebar-bar {
    width: 60px !important;
}
.page-header-fixed #dashboard .page-secondary-sidebar {
    padding-top: 61px;
}
.page-header-fixed #dashboard .page-header .navbar-default {
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
}
.page-header-fixed #dashboard .page-header.changeBg .navbar-default {
    background: #fff !important;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
}
.page-header-fixed #dashboard .page-container.container .page-header {
    padding-right: 15px;
    padding-left: 195px;
    left: 0;
    right: 0;
    margin: auto;
}
.page-header-fixed.page-sidebar-collapsed #dashboard .page-container.container .page-header {
    padding-right: 15px;
    padding-left: 75px;
    left: 0;
    right: 0;
    margin: auto;
}
@media (min-width: 768px) {
    .page-header-fixed #dashboard .page-container.container .page-header {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .page-header-fixed #dashboard .page-container.container .page-header {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .page-header-fixed #dashboard .page-container.container .page-header {
        width: 1170px;
    }
}
.page-sidebar-collapsed.page-header-fixed #dashboard .page-header {
    left: 61px;
}
#dashboard .page-header .form-control {
    background-color: #f1f3f6;
    color: #333;
    font-size: 13px;
}
#dashboard .page-header .form-control::placeholder {
    color: #cfd8e7;
}
.page-sidebar-fixed #dashboard .page-header {
    z-index: 41;
}
#dashboard .page-header .navbar-default {
    background-color: transparent;
    border: 0;
    margin-bottom: 0;
    border-radius: 0;
    padding: 0;
    border-bottom: 1px solid #e5e9ec;
}
#dashboard .page-header .navbar-default .container-fluid{
	padding:0;
}
#dashboard .page-header .navbar-default .container-fluid,
#dashboard .page-header .navbar-default .navbar-collapse {
    height: 56px !important;
}
#dashboard .page-header .navbar-default .navbar-brand i.fixed-sidebar-button-active {
    background: #fff;
}
#dashboard .page-header .navbar-nav > li.v-divider {
    display: block;
    width: 1px;
    height: 26px;
    background: #f3f3f3;
    margin: 21px 15px;
}
#dashboard .page-header .navbar-nav > li > a {
    display: block;
    color: #000000;
}
#dashboard .page-header .navbar-nav > li:nth-child(2) {
    margin: 0 30px;
}
#dashboard .page-header .navbar-nav > li > a:focus,
#dashboard .page-header .navbar-nav > li > a:hover,
#dashboard .page-header .navbar-nav > li > a:link {
    background: 0 0 !important;
}
#dashboard .page-header .navbar-nav > li > a::after {
    display: none;
}
#dashboard .page-header .navbar-nav > li > a > img {
    width: 100%;
    max-width: 25px;
}
#dashboard .active-page.show > a {
    background-color: #fff;
    color: #2b2b2b;
    font-weight: 500;
}
#dashboard .active-page.show > a i {
    color: #2ec5d3;
}
#dashboard .navbar-default .navbar-nav > .show > a,
#dashboard .navbar-default .navbar-nav > .show > a:focus,
#dashboard .navbar-default .navbar-nav > .show > a:hover {
    color: #1f2229;
    background-color: transparent;
}
#dashboard .page-header .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
    overflow-x: visible;
}
@media (min-width: 768px) {
    #dashboard .navbar-nav {
        float: left;
        margin-left: 0;
        margin-right: 0;
        padding: 0;
    }
}
#dashboard .page-right-sidebar {
    width: 230px;
    background: #fff;
    height: 100%;
    min-height: 100%;
    right: -230px;
    top: 0;
    z-index: 42;
    position: fixed;
    box-shadow: 0 0 9px rgba(191, 191, 191, 0.3);
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
}
#dashboard .page-right-sidebar.visible {
    right: 0;
}
#dashboard .page-right-sidebar#chat-right-sidebar {
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -o-transition: all 0.1s;
    transition: all 0.1s;
    opacity: 0;
}
#dashboard .page-right-sidebar#chat-right-sidebar.visible {
    opacity: 1;
}
#dashboard .right-sidebar-top {
    background: #fff;
    display: block;
    height: 61px;
    border-bottom: 1px solid #e5e9ec;
}
#dashboard .page-right-sidebar .right-sidebar-close {
    float: right;
    color: #4f4f4f;
    font-size: 20px;
    padding: 2px 19px;
    height: 56px;
    line-height: 56px;
}
#dashboard .page-right-sidebar .right-sidebar-close:hover {
    background: #fff;
}
#dashboard .page-right-sidebar .chat-sidebar-close {
    color: #4f4f4f;
    font-size: 24px;
    padding: 14px 20px;
    height: 56px;
}
#dashboard .right-sidebar-tabs {
    float: left;
    width: 172px;
    height: 60px;
}
#dashboard .right-sidebar-tabs li {
    width: 50%;
}
#dashboard .right-sidebar-tabs a {
    height: 60px;
    line-height: 40px !important;
    font-size: 13px;
    color: #626262;
    text-transform: capitalize;
    font-weight: 700 !important;
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border: 0 !important;
    margin-right: 0 !important;
}
#dashboard .right-sidebar-tabs .nav-tabs {
    border-bottom: 0 !important;
}
#dashboard .right-sidebar-tabs .nav-tabs > li.active > a,
#dashboard .right-sidebar-tabs .nav-tabs > li.active > a:focus,
#dashboard .right-sidebar-tabs .nav-tabs > li.active > a:hover {
    cursor: default;
    background-color: #fff;
    border: none;
    border-radius: 0;
}
#dashboard .right-sidebar-tabs .nav > li > a:focus,
#dashboard .right-sidebar-tabs .nav > li > a:hover {
    text-decoration: none;
    background-color: #fff;
}
#dashboard .chat-list .chat-item {
    overflow: hidden;
    position: relative;
    display: block;
    border-bottom: 1px solid #e5e9ec;
    padding: 5px 25px;
}
#dashboard .chat-list .chat-item:last-child {
    border-bottom: 1px solid transparent;
}
#dashboard .chat-list .chat-item:hover {
    background: #f9f9f9;
}
#dashboard .chat-list .chat-item img {
    float: left;
    width: 40px;
    border-radius: 100%;
    margin: 10px;
    margin-left: 0;
}
#dashboard .chat-list .chat-item .user-avatar {
    position: relative;
    display: inline-block;
    overflow: hidden;
    float: left;
}
#dashboard .chat-list .chat-item.active-user .user-avatar:after {
    content: " ";
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #2ec5d3;
    position: absolute;
    bottom: 9px;
    right: 9px;
    border: 2px solid #fff;
}
#dashboard .chat-list .chat-item .chat-info {
    float: left;
    overflow: hidden;
    padding: 13px 0;
    width: 100px;
}
#dashboard .chat-list .chat-item .chat-info span.chat-author {
    display: block;
    color: #4f5862;
    font-size: 13px;
    line-height: 15px;
    padding: 0;
    margin-bottom: 4px;
}
#dashboard .chat-list .chat-item .chat-info span.chat-text {
    display: block;
    color: #4f4f4f;
    font-size: 13px;
    line-height: 15px;
    padding: 0;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
#dashboard .chat-list .chat-item.unread .chat-info span {
    color: #2b2b2b !important;
    font-weight: 500;
}
#dashboard .chat-list .chat-item .chat-info span.chat-time {
    display: block;
    color: #626262;
    opacity: 0.6;
    font-size: 12px;
    line-height: 15px;
    padding: 0;
    margin: 0;
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -7.5px;
}
#dashboard .load-more-messages {
    display: block;
    text-align: center;
    width: 100%;
    margin: 15px auto;
    font-size: 14px;
    color: #4f5862;
    opacity: 0.6;
}
#dashboard .load-more-messages:hover {
    color: #4f5862;
    opacity: 1;
}
#dashboard .chat-top-info {
    float: left;
    padding: 10px 0 9px 20px;
}
#dashboard .chat-top-info span {
    display: block;
}
#dashboard .chat-top-info span.chat-name {
    color: #4f5862;
    font-size: 14px;
    font-weight: 400;
}
#dashboard .chat-top-info span.chat-state {
    color: #4f5862;
    font-size: 12px;
    opacity: 0.6;
}
#dashboard .chat-more-nav {
    float: right;
    display: block;
    position: relative;
    width: 15px;
    height: 15px;
    margin: 13px 5px;
    text-align: center;
}
#dashboard .chat-more-nav > a {
    display: block;
    position: relative;
    font-size: 18px;
    width: 100%;
    height: 100%;
}
#dashboard .chat-start-date {
    width: 100%;
    color: #626262;
    opacity: 0.6;
    font-size: 12px;
    text-align: center;
    padding: 10px 0;
}
#dashboard .page-right-sidebar-inner {
    height: 100%;
}
#dashboard .chat-header {
    font-size: 14px;
    font-weight: 700;
    line-height: 60px;
    padding-left: 21px;
    color: #333;
}
#dashboard .right-sidebar-content {
    height: calc(100% - 61px);
}
#dashboard .right-sidebar-content .slimScrollDiv {
    height: calc(100% - 55px) !important;
    width: 100% !important;
}
#dashboard .right-sidebar-chat {
    height: 100% !important;
    padding-bottom: 20px;
}
#dashboard .chat-bubble.them .chat-bubble-img-container {
    float: left;
}
#dashboard .chat-bubble .chat-bubble-text-container {
    max-width: 60%;
    display: block;
}
#dashboard .chat-bubble.them .chat-bubble-text-container {
    float: left;
}
#dashboard .chat-bubble.me .chat-bubble-text-container {
    float: right;
}
#dashboard .chat-bubble {
    position: relative;
    width: 100%;
    display: block;
    overflow: hidden;
}
#dashboard .chat-bubble img {
    width: 38px;
    height: 38px;
    border-radius: 100%;
}
#dashboard .chat-bubble.them img {
    margin-left: 18px;
}
#dashboard .chat-bubble span.chat-bubble-text {
    padding: 7px 12px;
    min-height: 28px;
    margin: 0 10px;
    position: relative;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.8;
    float: left;
    clear: both;
    word-wrap: break-word;
    max-width: 100%;
}
#dashboard .chat-bubble.them span.chat-bubble-text {
    margin-left: 10px;
    background: #f3f3f3;
}
#dashboard .chat-bubble.me span.chat-bubble-text {
    margin-left: 10px;
    margin-right: 28px;
    background: #dff1fd;
    float: right;
}
#dashboard .chat-bubble span.chat-bubble-text {
    margin-top: 4px;
}
#dashboard .chat-bubble span.chat-bubble-text:first-child {
    margin-top: 0;
}
#dashboard .chat-bubble span.chat-bubble-text:last-child {
    margin-bottom: 10px;
}
#dashboard .chat-bubble span.chat-bubble-text:first-child:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
}
#dashboard .chat-bubble.them span.chat-bubble-text:first-child:before {
    border-width: 0 10px 10px 0;
    border-color: transparent #f3f3f3 transparent transparent;
    position: absolute;
    top: 0;
    left: -9px;
}
#dashboard .chat-bubble.me span.chat-bubble-text:first-child:before {
    border-width: 10px 10px 0 0;
    border-color: #dff1fd transparent transparent;
    position: absolute;
    top: 0;
    right: -9px;
}
#dashboard .chat-write input {
    background: 0 0;
    border: 0;
    box-shadow: none;
    border-top: 1px solid #e5e9ec;
    height: 60px;
    border-radius: 0;
    padding: 0 20px;
    font-weight: 400;
}
#dashboard .chat-write input:focus {
    box-shadow: none;
    border-color: #e5e9ec;
}
#dashboard .right-sidebar-settings .settings-title {
    padding: 10px 25px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    display: block;
    margin: 5px 0 0 0;
    background: 0 0;
    color: #4f5862;
    opacity: 0.6;
}
#dashboard span.chat-title {
    padding: 10px 25px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    display: block;
    margin: 5px 0 0 0;
    background: 0 0;
    color: #4f5862;
    opacity: 0.6;
}
#dashboard .sidebar-setting-list li {
    padding: 12px 25px;
    font-size: 13px;
    color: #4f5862;
}
#dashboard .sidebar-setting-list li:last-child {
    border-color: transparent;
}
#dashboard .sidebar-setting-list li .switchery {
    float: right;
}
#dashboard .page-sidebar {
    width: 61px;
    background: #641eff;
    background: -moz-linear-gradient(top, #641eff 0, #a307dd 100%);
    background: -webkit-linear-gradient(top, #641eff 0, #a307dd 100%);
    background: linear-gradient(to bottom, #641eff 0, #a307dd 100%);
    min-height: 100%;
    float: left;
    z-index: 100;
    position: fixed;
    -webkit-box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.29);
    -moz-box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.29);
    box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.29);
}
#dashboard .page-sidebar-fixed .secondary-sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 100;
    height: 100% !important;
}
#dashboard .page-sidebar-fixed .secondary-sidebar .secondary-sidebar-menu {
    height: 100% !important;
    overflow: hidden;
}
.page-header-fixed #dashboard .secondary-sidebar {
    padding-top: 61px;
}
.page-header-fixed #dashboard .secondary-sidebar .secondary-sidebar-bar {
    z-index: 9;
    top: 0;
    width: 199px;
}
#dashboard .logo-box {
    background: #fff;
    border-radius: 15px;
    padding: 10px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}


#dashboard .page-sidebar .page-sidebar-menu,
#dashboard .page-sidebar .secondary-sidebar-menu,
#dashboard .page-sidebar .slimScrollDiv {
    height: 100%;
}
#dashboard .page-sidebar .page-sidebar-menu ul {
    list-style: none;
    padding: 0;
    width: 61px;
}
#dashboard .page-sidebar .page-sidebar-menu ul li a {
    padding: 8px 8px;
    line-height: 25px;
    margin: 5px 10px;
    border-radius: 50%;
    width: 100%;
    display: block;
    color: #fff;
    text-align: center;
    width: auto;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}
#dashboard .page-sidebar .page-sidebar-menu ul li a:hover {
    background: rgba(255, 255, 255, 0.15);
}



#dashboard .secondary-sidebar .slimScrollBar {
    display: none !important;
}
.collapsed-sidebar.page-sidebar-fixed #dashboard .secondary-sidebar-menu {
    overflow: visible !important;
}
#dashboard .secondary-sidebar .slimScrollDiv {
    overflow: visible !important;
}
#dashboard .secondary-sidebar-menu ul {
    padding: 0;
    margin: 0;
}
#dashboard .secondary-sidebar-menu ul li {
    list-style-type: none;
    position: relative;
}
#dashboard .secondary-sidebar-menu ul li a {
    display: block;
    font-weight: 400;
    font-size: 16px;
    padding: 12px 0 12px 75px;
    color: #BFE6E7;
    position: relative;
    margin: 5px 0;
    border-radius: 0 5px 5px 0;
}
#dashboard .secondary-sidebar-menu ul li a span {
    position: absolute;
    width: 50px;
    height: 50px;
    color: rgb(253 253 253 / 85%);
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
}

#dashboard .secondary-sidebar-menu ul li.active-page a, #dashboard .secondary-sidebar-menu ul li a:hover, #dashboard .secondary-sidebar-menu ul li.open > a {
    color: #23A5A7;
}

#dashboard .secondary-sidebar-menu ul li.active-page a span img,
#dashboard .secondary-sidebar-menu ul li.open > a span img,
#dashboard .secondary-sidebar-menu ul li a:hover span img{
	filter: none;
}
#dashboard .secondary-sidebar-menu ul li.active-page > a,
#dashboard .secondary-sidebar-menu ul li.open > a,
#dashboard .secondary-sidebar-menu ul li > a:hover {
	background: #fff;
}
#dashboard .secondary-sidebar-menu ul li a span img {
    filter: brightness(10) opacity(0.65);
    width: 100%;
    max-width: 20px;
}

#dashboard .secondary-sidebar-menu ul li a:link {
    text-decoration: none;
}
#dashboard .secondary-sidebar-menu > ul > li > a:hover
 {
    text-decoration: none;
    color: #23A5A7;
}
#dashboard .secondary-sidebar-menu > ul > li > a:hover .accordion-icon{
border-color: #23A5A7;
}
#dashboard .secondary-sidebar-menu ul li a .accordion-icon {
    float: right;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    margin-top: 6px;
    position: relative;
    right: 15px;
    border: solid #ABDEDF;
    border-width: 0 2px 2px 0;
    padding: 4px;
    transform: rotate(-45deg);
}
#dashboard .secondary-sidebar-menu ul li a span.badge {
    margin: 2px 0;
    float: right;
}
#dashboard .secondary-sidebar-menu ul li.open a .accordion-icon {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
	border-color: #23A5A7;
	top: -3px;
}

#dashboard .secondary-sidebar-menu ul li .sub-menu {
    position: relative;
    margin: 0 0 15px;
}

.collapsed-sidebar #dashboard .secondary-sidebar {
    width: 70px;
    padding: 10px 3px 230px 0;
}
.collapsed-sidebar #dashboard .secondary-sidebar-bar {
    padding-left: 10px;
}
.collapsed-sidebar #dashboard .page-inner {
    width: calc(100% - 70px);
}
.collapsed-sidebar #dashboard .secondary-sidebar .secondary-sidebar-bar .logo-box {
    height: 50px;
    width: 50px;
}
.collapsed-sidebar #dashboard .secondary-sidebar .secondary-sidebar-bar .logo-box:first-letter {
    visibility: visible;
    font-size: 18px;
}
.collapsed-sidebar #dashboard .secondary-sidebar-profile > a {
    display: block;
}
.collapsed-sidebar #dashboard .secondary-sidebar-menu ul li a span {
    top: -1px;
}
.collapsed-sidebar #dashboard .secondary-sidebar-menu ul li a .accordion-icon {
    border-width: 0 1px 1px 0;
    padding: 3px;
    position: absolute;
    top: 16px;
    right: 6px;
}
.collapsed-sidebar #dashboard .secondary-sidebar-profile {
    padding-left: 15px;
}
/*.collapsed-sidebar #dashboard .secondary-sidebar .secondary-sidebar-menu .accordion-menu li a .accordion-icon,*/
.collapsed-sidebar #dashboard .secondary-sidebar .secondary-sidebar-menu .accordion-menu li a label {
    display: none;
}
.collapsed-sidebar #dashboard .secondary-sidebar .secondary-sidebar-menu .accordion-menu > li > a {
    padding: 25px;
    text-align: center;
}
.collapsed-sidebar #dashboard .secondary-sidebar-menu ul li a span {
    left: 10px;
}
.collapsed-sidebar #dashboard .secondary-sidebar .secondary-sidebar-menu .accordion-menu > li > a i.menu-icon {
    margin-right: 0;
}
.collapsed-sidebar #dashboard .secondary-sidebar .secondary-sidebar-menu .accordion-menu > li:hover {
    width: 100%;
    z-index: 999;
}
.collapsed-sidebar #dashboard .accordion-menu .sub-menu li.animation a {
    padding: 20px;
}
.collapsed-sidebar #dashboard .secondary-sidebar-menu ul li li a span {
    top: -6px;
}
/*.collapsed-sidebar #dashboard .secondary-sidebar .secondary-sidebar-menu .accordion-menu li a + .sub-menu li a {
    padding: 7px 20px;
}
.collapsed-sidebar #dashboard .secondary-sidebar .secondary-sidebar-menu .accordion-menu li a:hover + .sub-menu,
.collapsed-sidebar #dashboard .secondary-sidebar .secondary-sidebar-menu .accordion-menu li:hover .sub-menu {
    display: block !important;
    position: absolute;
    left: 61px;
    z-index: 999;
    top: 0;
    background: #fff;
    width: 150px;
    opacity: 1;
    height: auto;
    border: 1px solid #e5e9ec;
    padding: 10px 0;
    -webkit-transition: opacity 0.2s, left 0.2s;
    -moz-transition: opacity 0.2s, left 0.2s;
    -o-transition: opacity 0.2s, left 0.2s;
    transition: opacity 0.2s, left 0.2s;
}
.collapsed-sidebar #dashboard .secondary-sidebar .secondary-sidebar-menu .accordion-menu li a + .sub-menu li a {
    opacity: 1;
}
.collapsed-sidebar #dashboard .secondary-sidebar .secondary-sidebar-menu .accordion-menu li a + .sub-menu {
    opacity: 0;
    left: 81px;
    display: block !important;
    width: 0;
    height: 0;
    margin-left: 12px;
    border: none;
    -webkit-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
	position: absolute;
    top: 0;
}

.collapsed-sidebar #dashboard .secondary-sidebar .secondary-sidebar-menu .accordion-menu li a + .sub-menu:before {
    position: absolute;
    top: 15px;
    left: -8px;
    right: auto;
    display: inline-block !important;
    border-top: 8px solid transparent;
    border-right: 8px solid #e5e9ec;
    border-bottom: 8px solid transparent;
    content: "";
    display: none;
}
.collapsed-sidebar #dashboard .secondary-sidebar .secondary-sidebar-menu .accordion-menu li a + .sub-menu:after {
    position: absolute;
    top: 16px;
    left: -7px;
    right: auto;
    display: inline-block !important;
    border-top: 7px solid transparent;
    border-right: 7px solid #fff;
    border-bottom: 7px solid transparent;
    content: "";
    display: none;
}*/
#dashboard .profile-menu {
    height: 61px;
}
#dashboard .settings-menu-btn {
    height: auto;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 15px 0;
}
#dashboard .settings-menu-btn a {
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    width: 100%;
    display: block;
    padding: 5px 10px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}
#dashboard .page-sidebar-inner {
    height: 100% !important;
}
#dashboard .page-sidebar > .page-sidebar-inner {
    position: absolute !important;
    height: auto !important;
    top: 50%;
    transform: translateY(-50%);
}
#dashboard .profile-menu a {
    display: block;
    padding: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
#dashboard .profile-menu a img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
}
#dashboard .content-header {
    display: block;
    overflow: hidden;
    margin: 0 0 30px;
}
#dashboard .page-title {
    font-size: 32px;
    color: #000;
    margin: 0;
    font-weight: 300;
}
#dashboard .content-header .page-options {
    float: right;
    padding-right: 5px;
}
#dashboard .settings-sidebar {
    z-index: 1021;
    position: fixed;
    height: 100%;
    width: 261px;
    background: #fff;
    border-right: 1px solid #e5e9ec;
    transform: translateX(-100%);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}
#dashboard .settings-overlay {
    z-index: 1020;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    transition: opacity 0.5s;
}
#dashboard.settings-visible .settings-overlay {
    opacity: 1;
    right: 0;
    width: 100%;
}
#dashboard.settings-visible .settings-sidebar {
    transform: translateY(0);
}
#dashboard .settings-sidebar-header {
    height: 61px;
    border-bottom: 1px solid #e5e9ec;
}
#dashboard .settings-menu-close {
    height: 60px;
    width: 60px;
    line-height: 60px;
    display: block;
    float: right;
    text-align: center;
    font-size: 18px;
}
#dashboard .settings-sidebar-header span {
    font-size: 14px;
    font-weight: 700;
    line-height: 60px;
    padding-left: 21px;
    color: #333;
}
#dashboard .secondary-sidebar {
    min-height: 100%;
    float: left;
    width: 260px;
    /*border-right: 1px solid #e5e9ec;
    background: #fff;*/
}
/*#dashboard .secondary-sidebar-bar {
    border-bottom: 1px solid #e5e9ec;
}*/
#dashboard .accordion-menu .sub-menu {
    display: none;
    background: 0 0;
}
#dashboard .accordion-menu .sub-menu li:not(.animation) a {
    opacity: 0;
}
#dashboard .accordion-menu .sub-menu li.animation a {
    opacity: 1;
}
#dashboard .accordion-menu .sub-menu li a {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-transform: capitalize;
    color: #BFE6E7;
    padding-left: 90px;
    margin-right: 10px;
}
#dashboard .secondary-sidebar-menu ul li li a span {
    left: 40px;
    top: -5px;
}
#dashboard .secondary-sidebar-menu ul li li a span img {
    max-width: 15px;
}
#dashboard .secondary-sidebar-menu ul li a label {
    margin: 0;
    cursor: pointer;
}
#dashboard .accordion-menu .sub-menu li a.active {
    color: #000;
}
#dashboard .accordion-menu .sub-menu li a.active:hover {
    color: #000 !important;
}
#dashboard .search-form {
    position: absolute;
    width: 100%;
    z-index: 41;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    font-weight: 400;
    opacity: 0;
    height: 56px;
    top: -100%;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0 50px;
}
#dashboard .search-form input {
    border: none;
    height: 100%;
    box-shadow: none;
    padding-left: 25px;
    padding-bottom: 7px;
    overflow: hidden;
}
#dashboard .search-form #close-search {
    background-color: #ffffff;
    color: #AEB5C5;
    border: none;
    height: 100%;
    box-shadow: none;
    padding-right: 15px;
}
#dashboard .search-form #close-search i {
    font-size: 20px;
    vertical-align: text-top;
}
#dashboard .search-form input:focus {
    border: none;
    box-shadow: none;
}
#dashboard .search-form #close-search:focus,
#dashboard .search-form #close-search:hover {
    opacity: 1;
    border: none;
    outline: 0;
    box-shadow: none;
}
#dashboard .search-content {
    position: absolute;
    background: #fff;
}
.search-open #dashboard .search-form {
    top: 0;
    opacity: 1;
}
/*#dashboard .page-content {
    min-height: 56px;
    vertical-align: bottom;
    background: #f1f3f6;
    width: calc(100% - 61px);
    float: right;
    margin-left: 61px;
}*/
#dashboard .page-sidebar-collapsed .page-content {
    width: calc(100% - 61px);
}
_::-webkit-full-page-media,
_:future,
:root #dashboard .page-content {
    vertical-align: top;
}
#dashboard .page-inner {
    position: relative;
    padding: 0 50px;
    width: calc(100% - 260px);
    height: 100%;
    float: right;
}
#dashboard .page-inner.white {
    background: #fff;
}
#dashboard .page-inner.full-page {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
}
#dashboard .page-inner.full-page.no-footer {
    padding-bottom: 0;
}
#dashboard .page-inner.full-page .page-footer {
    padding-left: 30px;
    padding-right: 30px;
}
_::-webkit-full-page-media,
_:future,
:root #dashboard .page-inner {
    padding-bottom: 0;
}
.page-header-fixed #dashboard .page-inner {
    margin-top: 61px;
}
#dashboard .page-desc {
    font-size: 15px;
    margin: 0;
}
#dashboard .img-preview {
    height: 150px;
    width: 220px;
    overflow: hidden;
    margin-bottom: 10px;
}
#dashboard .image-crop img {
    width: 100%;
    height: 100;
}
#dashboard .crop-img-text {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 15px;
}
#dashboard .slimScrollBar {
    z-index: 20 !important;
}
#dashboard .invoice-header {
    padding: 0 0 30px 0;
    display: block;
}
#dashboard .invoice-header h5 {
    font-size: 22px;
    font-weight: 700;
}
#dashboard .invoice-info {
    display: block;
}
#dashboard .invoice-info p span {
    float: right;
}
#dashboard .error-bg {
    display: block;
    background: #694ffc;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%;
}
#dashboard .error-bg::after {
    background-image: url(../images/error.gif);
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    opacity: 0.1;
}
#dashboard .error-coming-soon .error-bg::after {
    background-image: url(../images/construction.png);
}
#dashboard .error-info {
    position: absolute;
    right: 0;
    left: 50%;
    top: 0;
    bottom: 0;
    text-align: right;
}
#dashboard .error-info .error-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 50px;
    width: 100%;
    right: 0;
}
#dashboard .error-info .error-text .error-header h3 {
    font-size: 152px;
    color: #694ffc;
}
#dashboard .error-info .error-text .error-body {
    font-size: 15px;
}
#dashboard .error-coming-soon .error-text .error-header h3 span {
    font-size: 32px;
    font-weight: 700;
    width: 80px;
    display: inline-block;
}


#dashboard .login-box .login-body .form-group {
    border: 1px solid rgba(196, 196, 196, 0.7);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    height: 60px;
    align-items: center;
    margin: 16px 0 19px 0;
}
#dashboard .login-box .login-body  .form-control {
    border: 0;
    color: #232323;
    font-size: 16px;
}
.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #232323;
}
.form-control::-moz-placeholder { /* Firefox 19+ */
  color: #232323;
}
.form-control:-ms-input-placeholder { /* IE 10+ */
  color: #232323;
}
.form-control:-moz-placeholder { /* Firefox 18- */
  color: #232323;
}
#dashboard .login-box .login-body  .form-control:focus{
	outline: none;
}
#dashboard .login-box .login-body form label {
    color: #232323;
    padding-left: 15px;
}
#dashboard .login-box .login-body,
#dashboard .login-box .login-footer,
#dashboard .login-box .login-header {
    display: block;
    clear: both;
}
#dashboard .login-box .login-body{
    padding: 0 40px 0 40px;
}
#dashboard .login-box .login-header{
    padding: 0 30px 0 30px;
}
#dashboard .login-box .login-header h3 {
    font-size: 28px;
    color: #232323;
    font-weight: 600;
    margin: 14px 0 0px 0;
    text-align: center;
}
#dashboard .login-box .login-header p {
    color: #484848;
    font-size: 16px;
    margin: 9px 0 0;
    text-align: center;
}
#dashboard .login-box .login-body p{
	margin-bottom: 1rem;
}
#dashboard .login-box .login-body a, 
#dashboard .login-box .login-body a:hover {
    color: #59968A;
}
.custom-checkbox .custom-control-label {
    padding-left: 10px;
}
#dashboard .login-box .login-body .btn-primary {
    background-color: #23A5A7;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    color: #fff;
    width: 100%;
    margin: 25px 0 0;
    border: 0;
    border-radius: 10px;
    height: 60px;
    font-size: 18px;
    font-weight: 500;
    padding: 15px 0 15px 0;
}
#dashboard .login-box .login-body .forgot {
    text-align: left;
    margin: 25px 0 0;
    width: 50%;
    float: left;
}
#dashboard .login-box .login-body .forgot a {
    color: #232323;
}
.login-footer p {
    color: #404040;
    text-align: center !important;
    font-size: 12px;
}
#dashboard .register .login-bg::after {
    background-image: url(../images/register.png);
}
#dashboard .lockscreen .login-bg::after {
    background-image: url(../images/lock.png);
}
#dashboard .help-menu {
    display: block;
    padding-left: 30px;
    border-left: 1px solid #e5e9ec;
}
#dashboard .help-menu h3 {
    font-size: 13px;
    font-weight: 700;
    color: #646464;
    text-transform: uppercase;
    margin-bottom: 10px;
}
#dashboard .help-menu ul li a {
    color: #646464;
    padding: 5px 0;
    display: block;
}
#dashboard .help-menu ul li a.active,
#dashboard .help-menu ul li a.active:hover {
    color: #844ff1;
    font-weight: 700;
}
#dashboard .help-menu ul li a:hover {
    font-weight: 700;
}
#dashboard .help-topics li a {
    display: block;
    margin: 5px 0;
    color: #646464;
}
#dashboard .help-topics li a:hover {
    text-decoration: underline !important;
    color: #844ff1;
}
#dashboard .help-topics {
    display: block;
    border: 1px solid #e5e9ec;
    padding: 30px;
    border-radius: 6px;
    margin-top: 15px;
}
#dashboard .help-topics h3 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 14px;
    text-transform: uppercase;
}
#dashboard .pricing {
    text-align: center;
    border: 1px solid #e5e9ec;
    font-size: 14px;
    padding-left: 0;
    border-radius: 6px;
}
#dashboard .pricing li:first-child,
#dashboard .pricing li:last-child {
    padding: 20px 13px;
}
#dashboard .pricing li {
    list-style: none;
    padding: 12px 8px;
}
#dashboard .pricing li + li {
    border-top: 1px solid #e5e9ec;
}
#dashboard .pricing h1 {
    font-size: 24px;
    color: #2b2b2b;
}
#dashboard .pricing h3 {
    margin-bottom: 0;
    font-size: 32px;
}
#dashboard .pricing span {
    font-size: 12px;
    color: #999;
    font-weight: 400;
}
#dashboard .pricing li:nth-last-child(2) {
    padding: 30px 13px;
}
#dashboard .mailbox-options {
    height: 41px;
}
#dashboard .mail-count {
    line-height: 41px;
    margin: 0 10px 0 10px;
}
#dashboard .mailbox-menu ul li a {
    color: #596b8c;
    display: block;
    margin: 0 0 15px 0;
}
#dashboard .mailbox-menu ul li a.active,
#dashboard .mailbox-menu ul li a:hover {
    color: #844ff1;
}
#dashboard .mailbox-menu ul li a i {
    font-size: 16px;
    width: 30px;
    display: inline-block;
    vertical-align: text-bottom;
}
#dashboard .email-list {
    max-height: 510px;
    overflow-y: auto;
    border-left: 1px solid #dde1e4;
    border-right: 1px solid #dde1e4;
}
#dashboard .email-list ul {
    margin-bottom: 0;
}
#dashboard .email-list-item {
    color: #000;
}
#dashboard .email-list-item .email-author img {
    width: 35px;
    border-radius: 100%;
}
#dashboard .email-list-item .email-author span.author-name {
    margin-left: 7px;
    font-weight: 700;
    line-height: 25px;
}
#dashboard .email-list-item .email-author span.email-date {
    float: right;
    opacity: 0.6;
    line-height: 25px;
    font-size: 12px;
}
#dashboard .email-list-item .email-info {
    display: block;
    margin-top: 8px;
}
#dashboard .email-list-item .email-info .email-subject {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
}
#dashboard .email-list-item .email-info .email-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    opacity: 0.7;
}
#dashboard .email-list li a:active,
#dashboard .email-list li a:focus,
#dashboard .email-menu li a:active,
#dashboard .email-menu li a:focus {
    color: #2b2b2b;
}
#dashboard .email-list li a {
    display: block;
    padding: 18px 12px;
    border-bottom: 1px solid #e0e0e0;
}
#dashboard .email-list li:last-child a {
    border-color: transparent;
}
#dashboard .email-list li a:hover {
    background: #f1f1f1;
}
#dashboard .email-list li.active a {
    background: #e4e6eb;
}
#dashboard .email-actions .compose {
    width: 100%;
}
#dashboard .mail-header {
    width: 100%;
    display: block;
    overflow: hidden;
}
#dashboard .mail-header .mail-title {
    float: left;
    line-height: 38px;
    font-size: 16px;
    font-weight: 700;
    color: #333;
}
#dashboard .mail-header .mail-actions {
    float: right;
}
#dashboard .mail-info {
    display: block;
    width: 100%;
    overflow: hidden;
    margin-top: 15px;
}
#dashboard .mail-info .mail-author {
    float: left;
}
#dashboard .mail-info .mail-author img {
    width: 50px;
    border-radius: 50px;
    border: 4px solid #ccc;
    float: left;
}
#dashboard .mail-info .mail-author-info {
    padding: 0 15px;
    display: inline-block;
    height: 50px;
}
#dashboard .mail-info .mail-author span.mail-author-name {
    display: block;
    font-weight: 700;
    line-height: 25px;
}
#dashboard .mail-info .mail-author span.mail-author-address {
    display: block;
    line-height: 25px;
}
#dashboard .mail-info .mail-other-info {
    float: right;
    display: inline-block;
}
#dashboard .mail-info .mail-other-info span {
    line-height: 50px;
}
#dashboard .todo-menu h3 {
    font-size: 13px;
    font-weight: 700;
    color: #646464;
    text-transform: uppercase;
    margin: 30px 0 10px 0 !important;
}
#dashboard .todo-menu ul li a {
    color: #646464;
    display: block;
    padding: 5px 0;
}
#dashboard .todo-menu ul li a:hover,
#dashboard .todo-menu ul li.active a {
    color: #844ff1;
}
#dashboard .todo-menu ul li a i {
    width: 25px;
    display: inline-block;
    vertical-align: text-bottom;
}
#dashboard .todo-list ul li a {
    display: block;
    background: #f2f4f5;
    border: 1px solid #e5e9ec;
    width: 100%;
    margin-bottom: 5px;
    padding: 8px 15px 8px 35px;
    color: #646464;
    border-radius: 6px;
}
#dashboard .todo-list ul li a.done,
#dashboard .todo-list ul li a.done:hover {
    text-decoration: line-through !important;
}
#dashboard .contacts-menu {
    display: block;
    margin-top: 30px;
}
#dashboard .contacts-menu ul li a {
    display: block;
    color: #646464;
    padding: 5px 0;
}
#dashboard .contacts-menu ul li a:hover {
    font-weight: 700;
}
#dashboard .contacts-menu ul li a.active {
    color: #333;
    font-weight: 700;
}
#dashboard .contacts-menu ul li a i {
    width: 8px;
    height: 8px;
    vertical-align: middle;
    display: inline-block;
    border-radius: 100%;
    margin-right: 10px;
}
#dashboard .contacts-menu ul li a.contacts-all i {
    background-color: #6c757d;
}
#dashboard .contacts-menu ul li a.contacts-work i {
    background-color: #844ff1;
}
#dashboard .contacts-menu ul li a.contacts-family i {
    background-color: #ed5060;
}
#dashboard .contacts-menu ul li a.contacts-friends i {
    background-color: #61cd66;
}
#dashboard .contacts-menu ul li a.contacts-other i {
    background-color: #4897fb;
}
#dashboard .contacts-menu ul li a span {
    float: right;
    color: #646464 !important;
    font-weight: 400 !important;
}
#dashboard .contact-list .table tbody th,
#dashboard .contact-list .table thead th:first-child {
    width: 40px;
    padding-left: 10px;
}
#dashboard .contact-list .table > tbody > tr > td,
#dashboard .contact-list .table > tbody > tr > th,
#dashboard .contact-list .table > tfoot > tr > td,
#dashboard .contact-list .table > tfoot > tr > th,
#dashboard .contact-list .table > thead > tr > td,
#dashboard .contact-list .table > thead > tr > th {
    padding: 20px 10px;
}
#dashboard .contact-list .table tbody tr td,
#dashboard .contact-list .table tbody tr th {
    cursor: pointer;
}
#dashboard .contact-person .person-header {
    width: 100%;
    display: block;
}
#dashboard .contact-person .person-header img {
    width: 120px;
    height: 120px;
    border-radius: 120px;
    margin: 0 auto;
    display: block;
}
#dashboard .contact-person .person-header h3 {
    font-size: 22px;
    font-weight: 700;
    color: #333;
    width: 100%;
    margin: 15px 0;
    text-align: center;
}
#dashboard .contact-person .person-header span {
    width: 100%;
    display: block;
    margin-bottom: 15px;
    text-align: center;
}
#dashboard .contact-person .person-body h4 {
    text-transform: uppercase;
    font-weight: 700;
    width: 100%;
    text-align: center;
    margin-top: 35px;
    display: block;
    font-size: 13px;
}
#dashboard .contact-person .person-body p {
    text-align: center;
    margin-top: 20px;
}
#dashboard .profile-cover {
    width: 100%;
    display: block;
    position: relative;
    border-radius: 6px;
    z-index: 1;
}
#dashboard .profile-cover img {
    width: 100%;
    border-radius: 6px;
}
#dashboard .profile-cover:after {
    display: block;
    content: " ";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 6px;
    background: linear-gradient(transparent, #000);
}
#dashboard .profile-header {
    display: block;
    width: 100%;
    margin-top: -110px;
    padding: 0 30px;
    overflow: hidden;
    z-index: 2;
    position: relative;
    margin-bottom: 20px;
}
#dashboard .profile-header .profile-img img {
    width: 150px;
    height: 150px;
    border-radius: 150px;
    border: 5px solid #fff;
}
#dashboard .profile-header .profile-img {
    float: left;
}
#dashboard .profile-header .profile-name {
    float: left;
    margin-top: 50px;
    margin-left: 30px;
}
#dashboard .profile-header .profile-name h3 {
    color: #fff;
    font-weight: 700;
    font-size: 22px;
    line-height: 50px;
}
#dashboard .profile-header .profile-header-menu {
    float: right;
    height: 100%;
    margin-top: 39px;
}
#dashboard .profile-header .profile-header-menu ul li {
    float: left;
    height: 100%;
    padding: 25px;
}
#dashboard .profile-header .profile-header-menu ul li a {
    color: #a1a1a1;
    display: block;
    font-weight: 700;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
#dashboard .profile-header .profile-header-menu ul li a.active,
#dashboard .profile-header .profile-header-menu ul li a:hover {
    color: #fff;
}
#dashboard .profile-about-list {
    margin-bottom: 0;
}
#dashboard .profile-about-list li {
    margin-bottom: 8px;
    position: relative;
}
#dashboard .profile-about-list li:last-child {
    margin-bottom: 0;
}
#dashboard .profile-about-list li i {
    font-size: 17px;
    width: 25px;
    position: absolute;
    top: 2px;
}
#dashboard .profile-about-list li span {
    padding-left: 25px;
    display: block;
}
#dashboard .post-body,
#dashboard .post-header {
    display: block;
    overflow: hidden;
    width: 100%;
}
#dashboard .post-header img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    float: left;
}
#dashboard .post-header .post-info {
    float: left;
    padding: 5px 15px 0 15px;
}
#dashboard .post-header .post-info span.post-author {
    font-size: 14px;
    font-weight: 700;
    color: #333;
}
#dashboard .post-header .post-info span.post-date {
    font-size: 13px;
}
#dashboard .post-header .post-header-actions {
    float: right;
    padding: 15px 0;
}
#dashboard .post-header .post-header-actions a {
    line-height: 20px;
    color: #646464;
}
#dashboard .post-header .post-header-actions a:hover {
    color: #333;
}
#dashboard .post-body {
    padding: 15px 0 0 0;
    position: relative;
}
#dashboard .post-body p {
    margin-bottom: 0;
}
#dashboard .post-body .post-image {
    display: block;
    width: 100%;
    border-radius: 6px;
    margin-top: 15px;
}
#dashboard .post-actions,
#dashboard .post-actions ul {
    display: block;
    overflow: hidden;
    margin-bottom: 0;
}
#dashboard .post-actions {
    border-top: 1px solid #e5e9ec;
    padding-top: 5px;
    margin-top: 15px;
}
#dashboard .post-actions ul li a {
    display: inline-block;
    float: left;
    padding: 5px 8px;
    color: #646464;
    font-size: 13px;
    margin-right: 10px;
}
#dashboard .post-actions ul li a:hover {
    color: #000;
}
#dashboard .post-actions ul li a.liked {
    color: #844ff1;
}
#dashboard .post-actions ul li a.liked i:before {
    content: "\f004";
}
#dashboard .post-actions ul li a i {
    margin-right: 7px;
}
#dashboard .post-comments {
    margin-top: 5px;
    border-top: 1px solid #e5e9ec;
    padding-top: 15px;
}
#dashboard .post-comments .post-comm {
    display: block;
    margin-bottom: 10px;
    background: #fafafa;
    border-radius: 6px;
    padding: 15px;
    overflow: hidden;
}
#dashboard .post-comments .post-comm:last-child {
    margin-bottom: 0;
}
#dashboard .post-comments .post-comm img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    float: left;
}
#dashboard .post-comments .post-comm .comment-container {
    float: left;
    padding: 0 10px;
    width: calc(100% - 30px);
    display: block;
}
#dashboard .post-comments .post-comm .comment-container span.comment-author {
    color: #333;
    font-weight: 700;
    display: block;
    line-height: 30px;
}
#dashboard .post-comments .post-comm .comment-container span.comment-author small {
    float: right;
    font-size: 12px;
    color: #646464;
}
#dashboard .post-comments .post-comm .comment-text {
    padding: 10px 0 0 0;
    display: inline-block;
}
#dashboard .story-list .story {
    overflow: hidden;
    display: block;
    margin-bottom: 10px;
}
#dashboard .story-list .story:last-child {
    margin-bottom: 0;
}
#dashboard .story-list .story img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 2px solid #4897fb;
    padding: 2px;
    float: left;
    display: inline-block;
}
#dashboard .story-list .story .story-info {
    display: inline-block;
    float: left;
    padding: 5px 15px;
}
#dashboard .story-list .story .story-info span.story-author {
    display: block;
    line-height: 20px;
    font-weight: 700;
    color: #333;
}
#dashboard .story-list .story .story-info span.story-time {
    line-height: 20px;
    font-size: 12px;
}
#dashboard .mutual-friends-list {
    display: block;
}
#dashboard .mutual-friends-list img {
    float: left;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 3px solid #fff;
    margin-left: -8px;
    z-index: 1;
    display: inline-block;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}
#dashboard .mutual-friends-list img:first-child {
    margin-left: 0;
}
#dashboard .mutual-friends-list img:hover {
    z-index: 999;
    transform: translateY(-5px);
}
#dashboard .mutual-friends-list span {
    line-height: 40px;
    padding: 0 10px;
}
#dashboard .info-card h4 {
    margin-bottom: 15px;
    font-size: 14px;
}
#dashboard .info-card h4 span {
    float: right;
}
#dashboard .info-card .progress {
    margin-bottom: 0;
}
#dashboard .ds-stat .ds-stat-name {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    color: #a0a0a0;
    margin-bottom: 10px;
    display: block;
}
#dashboard .ds-stat .ds-stat-number {
    font-size: 34px;
    font-weight: 400;
    color: #495057;
    position: relative;
    display: block;
}
#dashboard .ds-stat .ds-stat-number .ds-stat-percent {
    font-size: 13px;
    line-height: 25px;
    position: absolute;
    top: 0;
    display: inline-block;
    margin-left: 10px;
    font-weight: 400;
    color: #a0a0a0;
}
#dashboard .ds-stat .ds-stat-number .ds-stat-percent i {
    font-size: 24px;
    vertical-align: top;
    margin-right: 5px;
}
#dashboard .ds-stat .ds-stat-number .ds-stat-percent i.fa-caret-up {
    color: #61cd66;
}
#dashboard .ds-stat .ds-stat-number .ds-stat-percent i.fa-caret-down {
    color: #ed5160;
}
#dashboard .ds-stat .progress {
    margin-bottom: 0;
    margin-top: 10px;
}
#dashboard .popular-products {
    height: 365px;
    overflow: hidden;
    position: relative;
}
#dashboard .popular-products canvas {
    margin-top: 10px;
}
#dashboard .popular-product-list {
    margin-top: 10px;
}
#dashboard .popular-product-list ul li {
    padding: 12px 12px;
    border-bottom: 1px solid #e5e9ec;
}
#dashboard .popular-product-list ul li:last-child {
    border-bottom: none;
}
#dashboard .popular-product-list ul li span.product-color {
    float: right;
    display: inline-block;
    border-radius: 6px;
    background: #000;
    font-size: 11px;
    padding: 0 5px;
    line-height: 20px;
    font-weight: 700;
}
#dashboard .popular-product-list ul li#popular-product1 span.product-color {
    background: #6a4ffc;
    color: #fff;
}
#dashboard .popular-product-list ul li#popular-product2 span.product-color {
    background: #4897fb;
    color: #fff;
}
#dashboard .popular-product-list ul li#popular-product3 span.product-color {
    background: #d9e7f9;
    color: #646464;
}
#dashboard .logo-sm {
    display: none;
}

.hidden-sidebar #dashboard .page-sidebar,
.hidden-sidebar #dashboard .secondary-sidebar {
    margin-left: -100%;
    position: fixed;
    z-index: 999999;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
	top: 0;
    left: 0;
    border-radius: 0;
}
.hidden-sidebar #dashboard .settings-sidebar {
    z-index: 9999999;
}
.hidden-sidebar.page-sidebar-visible #dashboard .page-sidebar {
    margin-left: 0;
}
.hidden-sidebar.page-sidebar-visible #dashboard .secondary-sidebar {
    margin-left: 60px;
    height: 100%;
    overflow: auto;
}
.hidden-sidebar #dashboard .page-content {
    margin-left: 0;
    width: 100%;
}
.hidden-sidebar #dashboard .page-header,
.hidden-sidebar #dashboard .page-inner {
    width: 100%;
    float: none;
}

.hidden-sidebar #dashboard .logo-sm {
    display: block;
    float: left;
    width: 100%;
    height: 41px;
    display: flex;
    align-items: center;
}
.hidden-sidebar #dashboard .navbar-header {
    border-bottom: 1px solid #e5e9ec;
    background: #fff;
}
.hidden-sidebar #dashboard .logo-sm .logo-box {
    padding: 0;
    line-height: 41px;
    width: auto;
    position: absolute;
    right: 20px;
}
.hidden-sidebar #dashboard .logo-sm #sidebar-toggle-button {
    float: left;
    color: #000;
    z-index: 99999;
    position: relative;
    width: 40px;
    padding: 0;
    height: 41px;
    text-align: center;
    line-height: 41px;
}
.hidden-sidebar #dashboard .navbar-collapse {
    background: #fff;
    height: 61px;
}
.hidden-sidebar #dashboard .page-header .navbar-default .navbar-collapse {
    height: 59px !important;
    z-index: 9999;
    position: absolute;
    width: 100%;
    top: 42px;
}
.hidden-sidebar #dashboard .page-header .navbar-collapse .navbar-nav {
    height: 58px !important;
    justify-content: center !important;
}
.hidden-sidebar #dashboard .page-header .navbar-collapse .navbar-nav li a {
    padding: 11px 15px !important;
    height: 58px !important;
}
.hidden-sidebar #dashboard .navbar-header {
    width: 100%;
}
.hidden-sidebar #dashboard .navbar-header .navbar-toggler {
    float: right;
    position: relative;
    border-radius: 0;
    height: 41px;
    width: 40px;
    line-height: 41px;
    text-align: center;
    padding: 0;
    margin: 0;
    border: 0;
}
.hidden-sidebar #dashboard .navbar-header .navbar-toggler.focus,
.hidden-sidebar #dashboard .navbar-header .navbar-toggler:active,
.hidden-sidebar #dashboard .navbar-header .navbar-toggler:focus {
    box-shadow: none !important;
    border: none !important;
}
.hidden-sidebar #dashboard .navbar-nav {
    flex-direction: row;
    justify-content: center !important;
    width: 100%;
}
.hidden-sidebar #dashboard .navbar-nav li a + .dropdown-menu {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.hidden-sidebar #dashboard .page-header .search-form {
    z-index: 9999999;
    height: 100%;
    left: 0;
    border-radius: 0;
    position: fixed;
    padding: 0;
}
.hidden-sidebar #dashboard .page-header .search-form .search-input {
    height: 100%;
    border-radius: 0;
}
.hidden-sidebar #dashboard .page-header .search-form #close-search {
    height: 100%;
    width: 45px;
    line-height: 42px;
    text-align: center;
    padding: 0 5px 0 0;
    margin: 0;
	border-radius: 0;
}
.hidden-sidebar #dashboard .page-header .search-form #close-search i {
    vertical-align: inherit;
}
.hidden-sidebar #dashboard .search-form form, .hidden-sidebar #dashboard .search-form .input-group {
    border-radius: 0;
}

@media (max-width: 1200px) {
    #dashboard .email-actions {
        margin-top: 20px;
    }
    #dashboard .dashboard-panel {
        height: auto;
    }
    
    #dashboard .profile-header {
        margin-top: -60px;
    }
    #dashboard .profile-img {
        display: block;
        float: none !important;
    }
    #dashboard .profile-img img {
        margin: 0 auto;
        display: inherit;
        width: 100px !important;
        height: 100px !important;
        border-radius: 100px !important;
    }
    #dashboard .profile-name {
        display: block;
        float: none !important;
        text-align: center;
        margin: 0 !important;
    }
    #dashboard .profile-name h3 {
        color: #333 !important;
    }
    #dashboard .profile-header .profile-header-menu {
        display: block;
        float: none;
        margin: 0;
        text-align: center;
    }
    #dashboard .profile-header .profile-header-menu ul li {
        display: block;
        padding: 0;
        width: calc(100% / 6);
        text-align: center;
    }
    #dashboard .profile-header .profile-header-menu ul li a {
        padding: 10px;
        text-align: center;
        height: auto !important;
    }
    #dashboard .profile-header .profile-header-menu ul li a.active,
    #dashboard .profile-header .profile-header-menu ul li a:hover {
        color: #000;
    }
}
@media (max-width: 992px) {
    #dashboard .error-info {
        left: 0 !important;
        background: #fff;
        text-align: center;
    }
    #dashboard .error-text{
        float: none !important;
        width: 100% !important;
        margin: 0 auto !important;
        height: auto;
    }
    #dashboard .mailbox-search {
        margin-top: 10px;
    }
    #dashboard .email-list {
        border-left: 0;
        border-right: 0;
        margin-bottom: 30px;
    }
}
@media screen and (max-width: 767px){
	#dashboard .secondary-sidebar-menu ul li li a span {
		top: 2px;
	}
	#dashboard .accordion-menu .sub-menu li a {
		padding-left: 80px;
	}

    #dashboard .login-box .login-body {
        padding: 0 17px 0 17px;
    }
    
}
@media (max-width: 576px) {
   
    #dashboard .mailbox-options button {
        display: block;
        width: 100%;
        margin: 0 0 5px 0 !important;
    }
    #dashboard .mailbox-options {
        display: block !important;
    }
    #dashboard .mail-count {
        margin: 0 auto;
        display: block;
        text-align: center;
        width: auto;
    }
    #dashboard .mail-left-btn {
        float: left;
        display: inline-block;
        width: auto !important;
    }
    #dashboard .mail-right-btn {
        float: right;
        display: inline-block;
        width: auto !important;
    }
}
#dashboard pre.prettyprint {
    font-size: 13px;
}
#dashboard .color-pallete {
    position: relative;
    margin-bottom: 10px;
}
#dashboard .color-item {
    text-align: center;
    padding: 15px;
}
#dashboard .color-item span {
    font-weight: 700;
    font-size: 12px;
}

.react-datepicker-wrapper{
    width: 100% !important;
}