html, body {
    height: 100%!important;
    background-color: #f8f8f8;
}
div#root {
  height: 100%;
}
p:last-child{
	margin:0 ;
}
.form-control:focus {
    outline: 0;
    box-shadow: none;
}
.btn-theme {
  background-color: #3cb7b4;
  color: #fff;
  border-radius: 100px;
  padding: 5px 20px 5px 20px;
}
.control {
    position: relative;
    padding-left: 36px;
    margin-bottom: 10px;
    cursor: pointer;
    line-height: 33px;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
    position: absolute;
    top: 7px;
    left: 0;
    height: 20px;
    width: 20px;
    background: transparent;
    border: 1px solid rgba(196, 196, 196, 0.7);
    border-radius: 5px;
}

.control input:checked ~ .control__indicator {
    background: #23A5A7;
    border: 0;
}

.control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}

.control input:checked ~ .control__indicator:after {
  display: block;
}
.control--checkbox .control__indicator:after  {
    left: 7px;
    top: 3px;
    width: 6px;
    height: 11px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.control input:focus ~ .control__indicator:after{
	display: block;
}
.radio_block .yes_n {
    text-align: left;
    display: block;
    margin: 10px auto 0px;
}
.radio_block .yes_n input[type='radio'] + label:before {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.radio_block .yes_n input[type='radio']:checked + label:after {
    content: '\2022';
    display: flex;
    align-items: center;
    justify-content: center;
	font-size: 12px;
}

.radio_block .yes_n input[type='radio']:checked + label:after,
.radio_block .yes_n input[type='radio']:checked + label:hover:after {
  color: #fff;
}
.radio_block .yes_n input[type='radio'] {
  display: none;
  cursor: pointer;
}
.radio_block .yes_n input[type='radio']:focus,
.radio_block .yes_n input[type='radio']:active,
.radio_block .yes_n input[type='checkbox']:focus,
.radio_block .yes_n input[type='checkbox']:active {
  outline: none;
}
.radio_block .yes_n input[type='radio'] + label {
    cursor: pointer;
    display: inline-block!important;
    position: relative;
    padding-left: 35px;
    margin-right: 50px!important;
    width: auto;
    color: #0b4c6a;
    line-height: 18px;
    margin-bottom: 0;
}
.radio_block .yes_n input[type='radio'] + label:before,
.radio_block .yes_n input[type='radio'] + label:after {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    left: 0;
    bottom: 0;
    text-align: center;
    position: absolute;
}
.radio_block .yes_n input[type='radio'] + label:before {
    background: linear-gradient(0deg, #BFE6E7, #BFE6E7), #BFE6E7;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.radio_block .yes_n input[type='radio'] + label:after {
  color: #fff;
}
.radio_block .yes_n input[type='radio']:checked + label:before {
    background: linear-gradient(0deg, #23A5A7, #23A5A7), #23A5A7;
}

#steps .ma_in .radio_box_1 .heading {
  text-align: left;
}