.step{
  overflow: hidden;
  margin: 1em;
  display: block;
  cursor: default;
}

.horizontal.steps{
  display: flex;
}
.horizontal.step{
  width: auto;
}

.steps{

}

.clickable{
  cursor: pointer;
}

.done .icon{
  background-color: rgb(64, 192, 87);
  border-color: rgb(64, 192, 87);
  color: white;
  
}

.longDesc{
  text-align: left;
  margin-top: 1em;
  display: block;
  font-size: 0.9em;
  margin-left: 4em;
  margin-bottom: 1em;
}

.small .longDesc{
  display: none;
}

.small .icon{
  width: 2rem;
  height: 2rem;
  min-width: auto;
  margin-right: 10px;;
  font-size: 1em;
}

.small .step{
  margin: 1em;
  overflow: visible;
  width: auto;
  margin-right: 4em;
}

.small .icon svg{
  height: 13px;
  width: 13px;
}

.icon svg{
  height: 20px;
  width: 20px;
}

.small .title{
  font-size: 1em;
}

.small .description{
  font-size: 0.8em;
}

.active .icon{
  border-color: rgb(64, 192, 87);
}

.icon{

transition-property: transform, opacity;
transition-duration: 200ms;
transition-timing-function: ease;
transform-origin: center center 0px;
opacity: 1;
transform: scale(1);

float: left;
margin-right: 1em;
box-sizing: border-box;

height: 2.625rem;
width: 2.625rem;
min-width: 2.625rem;
border-radius: 2rem;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
background-color: #f1f3f5;
border: 0.125rem solid #f1f3f5;
  border-top-color: rgb(241, 243, 245);
  border-right-color: rgb(241, 243, 245);
  border-bottom-color: rgb(241, 243, 245);
  border-left-color: rgb(241, 243, 245);
-webkit-transition: background-color 150ms ease,border-color 150ms ease;
transition: background-color 150ms ease,border-color 150ms ease;
position: relative;
font-weight: 700;
color: #495057;
font-size: 1rem;
}
.title{
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
  -webkit-tap-highlight-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: 1.55;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-align: left;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1;
}
.description{
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
  -webkit-tap-highlight-color: transparent;
  color: #868e96;
  font-size: inherit;
  line-height: 1.55;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-align: left;
  margin-top: calc(1rem / 3);
  margin-bottom: calc(1rem / 3);
  font-size: calc(1rem - 0.125rem);
  line-height: 1;
}