input:focus,select:focus, button:focus{
    outline: none;
    box-shadow: none!important;
}
#profile {
    background: #FFFFFF;
    border-radius: 20px;
    overflow: hidden;
}
#profile .t_op {
  position: relative;
}
#profile .pr_ofile {
    padding: 50px;
}
#profile .up_pro {
    position: relative;
}
#profile .up_pro .profile_picture {
    width: 192px;
    height: 192px;
    border-radius: 50%;
    position: relative;
    margin: auto;
    overflow: hidden;
}
#profile .up_pro .profile_picture:before {
    position: absolute;
    content: "";
    background: rgba(0, 0, 0, 0.55);
    box-shadow: 4px 4px 34px rgb(35 165 167 / 23%);
    width: 100%;
    height: 100%;
}
#profile .up_pro .profile_picture img {
  width: 100%;
}
#profile .upload {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 192px;
    margin: auto;
    top: 0;
}
#profile .btn-upload {
    cursor: pointer;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30%;
}
#profile img.camera {
    width: 100%;
    max-width: 24px;
}


.choices__inner {
    font-size: 16px;
    font-weight: 400;
    border: 1px solid rgba(196, 196, 196, 0.7);
    box-sizing: border-box;
    border-radius: 10px;
    height: 65px;
    color: #232323;
    width: 100%;
    padding: 0 15px;
    background: transparent;
    min-height: 65px;
}
.choices__inner {
    padding: 15px;
    height: auto;
}
input.choices__input.choices__input--cloned {
    display: none;
}

.choices__list.choices__list--multiple > .choices__item.choices__item--selectable {
    background: #E8F0F0;
    border-radius: 38px;
    border: 0;
    color: #000000;
    font-size: 14px;
    padding: 10px;
}

.choices[data-type*=select-multiple] .choices__button, .choices[data-type*=text] .choices__button {
    border: 0;
    margin: 0 0 0 10px;
    background-image: url(../img/close.png);
}


.form-group.button-group button {
    border-radius: 10px;
    height: 65px;
    max-width: 210px;
    width: 100%;
    font-weight: 700;
    border: 1px solid;
    font-size: 16px;
}


/** view profile **/
#profile.view-profile .profile-edit-btn a {
    color: #23A5A7;
    font-size: 22px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: right;
}
#profile.view-profile .profile-edit-btn a img {
    margin-right: 10px;
}
#profile.view-profile .up_pro .profile_picture:before {
    content: none;
}
#profile.view-profile .form-group .form-control {
    height: auto;
    border: 0;
    font-size: 22px;
    padding: 0;
    border-radius: 0;
}
#profile.view-profile .t_op label.value {
    background: #E8F0F0;
    border-radius: 38px;
    border: 0;
    color: #000000;
    font-size: 14px;
    padding: 10px 15px;
    display: inline-block;
}
#profile.view-profile .pr_ofile {
    padding-bottom: 100px;
}
@media screen and (max-width: 1199px) {
  
  
  #profile .pr_ofile, #profile.view-profile .pr_ofile {
        padding: 35px 15px;
    }
  #profile .t_op.side {
    display: none;
  }
  
  
}
@media screen and (max-width: 767px) { 
  #profile .pr_ofile, #profile.view-profile .pr_ofile {
    padding: 35px 10px;
  }
  #profile.view-profile .form-group .form-control {
    font-size: 15px;
  }
}
