@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap");
/*rec media section */

.rec_img {
    width: 64px;
    height: 64px;
    border: 2px solid #23a5a7;
    object-fit: cover;
    padding: 3px;
}

.media .media-body h5.mt-0 {
    font-size: 18px;
    font-weight: 500;
    margin: 20px 0 5px 0;
}

.media .media-body p {
    font-size: 14px;
    font-weight: 400;
    color: #212529;
    margin: 5px 0 5px 0;
}

.media {
    position: relative;
}


.media-body a.medi_a {
    background: #23a5a7;
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    padding: 3px 10px 3px 10px;
    border-radius: 70px;
    margin: 0 10px 0 0px;
}

.media-body .l_no {
    color: #545454;
}

.react-confirm-alert-button-group button {
    background: #23a5a7;
    border-radius: 40px;
    font-weight: 500;
    font-family: 'Poppins';
    font-size: 15px;
}


.media .media-body .media {
    margin: 0 0 0 40px;
}

.media .media-body .media {
    position: relative;
}

.media .media-body .media:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 25px;
    background: rgb(138, 138, 138);
    left: -30px;
    top: -17px;
}

.media .media-body .media:after {
    content: '';
    position: absolute;
    width: 23px;
    height: 2px;
    background: rgb(138, 138, 138);
    left: -30px;
    top: 7px;
}


.media-body p.doc_comment {
    background: #fff;
    margin: 20px 0 20px 0;
    padding: 5px 9px;
    line-height: 19px;
    color: #000 !important;
    border-radius: 5px;
    box-shadow: 1px 1px 4px #d3d3d3;
}


/* comment by doctor */


div#accordion .card {
    height: auto;
    width: 100%;
    float: left;
    margin: 15px 0px 0 0;
}

div#accordion .card .card-header {
    padding: 6px 5px 4px 5px;
    background: #75bdbe;
    border-radius: 10px;
}

div#accordion .card .card-body {
    color: #604848;
}

div#accordion .card .card-header button {
    color: #ffffff;
    font-weight: 400;
    font-size: 15px;
    display: block;
    width: 100%;
    text-align: left;
}

/* end comment by doctor */

/* Top Bar Css*/
#dashboard .page-header-fix .profile_box {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #3d3d3d;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 54px;
    position: relative;
    overflow: hidden;
}

#dashboard .page-header-fix .profile_box img {
    display: block;
    object-fit: cover;
    width: 50px;
    height: 50px;
}

#dashboard .page-header-fix .profile_txt h3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #244142;
    margin: 0 0 3px;
}

#dashboard .page-header-fix .profile_txt h4 {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #7D9596;
    margin: 0;
}

#dashboard .page-header-fix .profile_txt .state {
    border-radius: 40px;
    padding: 3px 8px;
    font-weight: 600;
    font-size: 8px;
    line-height: 12px;
    color: #FFFFFF;
    display: inline-block;
    margin-left: 5px;
}

#dashboard .page-header-fix .profile_txt .state.on {
    background: #72C806;
}

#dashboard .page-header-fix .profile_txt {
    padding: 0 0 0 10px;
}

#dashboard .page-header-fix .mob-header {
    padding-top: 20px;
}


/*topbar profile css*/


/* universal button */

.theme-button {
    border-radius: 50px !important;
    height: 49px;
    max-width: 184px;
    width: 100%;
    font-weight: 500 !important;
    border: 2px solid !important;
    font-size: 16px !important;
    line-height: 31px !important;
}

.theme-button:hover,
.theme-button:focus {
    color: #fff !important;
    border-color: transparent !important;
    background-color: #118c8e !important;
}

.theme-button.submit-btn {
    background: #23A5A7;
    border-color: transparent;
    color: #fff;
}

/* universal button */


/* doctor status toggle */


.toggle {
    width: 35px;
    height: 20px;
    border-radius: 100px;
    background-color: #ddd;
    margin: -17px 0;
    overflow: hidden;
    box-shadow: inset 0 0 2px 1px rgb(0 0 0 / 5%);
    position: absolute;
}

.check {
    position: absolute;
    display: block;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 6;
}

.check:checked~.track {
    box-shadow: inset 0 0 0 20px #4bd863;
}

.check:checked~.switch {
    right: 2px;
    left: 17px;
    transition: .35s cubic-bezier(0.785, 0.135, 0.150, 0.860);
    transition-property: left, right;
    transition-delay: .05s, 0s;
}

.switch {
    position: absolute;
    left: 2px;
    top: 2px;
    bottom: 2px;
    right: 17px;
    background-color: #fff;
    border-radius: 36px;
    z-index: 1;
    transition: .35s cubic-bezier(0.785, 0.135, 0.150, 0.860);
    transition-property: left, right;
    transition-delay: 0s, .05s;
    box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
}

.track {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transition: .35s cubic-bezier(0.785, 0.135, 0.150, 0.860);
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, .05);
    border-radius: 40px;
}


/* end status css */


.patient_data {
    background: #fff;
    border-radius: 20px;
    padding: 60px 145px 60px 60px;
}

.gender {
    background: #EAF1FC;
    border-radius: 12.5px;
    font-weight: 500;
    font-size: 15px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0 5px 0;
}

.gender.male {
    background: #EAF1FC;
    color: #0267FF;
}

.gender.female {
    background: #FCEEF0;
    color: #FF0000;
}


.patient_table .table_wrap .dropdown {
    height: 7px;
    margin-right: 20px;
}

.patient_table .table_wrap .dropdown button {
    background: transparent;
    border: 0;
    padding: 0;
    width: 30px;
    height: 7px;
    position: absolute;
    top: 0px;
    left: 0;
}

.patient_table .table_wrap .dropdown button::after {
    display: none;
}

.patient_table .table_wrap .dropdown span {
    background: #BFE6E7;
    border: 0;
    border-radius: 30px;
    padding: 0;
    position: absolute;
    width: 7px;
    height: 7px;
    right: 10px;
    top: 0;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
}

.patient_table .table_wrap .dropdown span:before,
.patient_table .table_wrap .dropdown span:after {
    position: absolute;
    content: "";
    height: 7px;
    background: #BFE6E7;
    border-radius: 30px;
    width: 7px;
    top: 0;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
}

.patient_table .table_wrap .dropdown span:before {
    left: -10px;
}

.patient_table .table_wrap .dropdown span:after {
    right: -10px;
}

body {
    font-family: 'poppins', sans-serif !important;
    color: #244142;
}

button:focus,
input:focus {
    outline: none;
    box-shadow: none;
}

#dashboard .page-sidebar .page-sidebar-menu ul li a {
    color: #fff;
}

#dashboard .secondary-sidebar {
    background: #21a2a8;
    border-radius: 30px 0 0px 30px;
    border-right: none;
    height: 100%;
    padding: 25px 5px 120px 0px;
    position: fixed;
}

#dashboard .secondary-sidebar-bar {
    border-bottom: 0;
    padding-left: 20px;
}

#dashboard .login-logo {
    padding: 15px 0 15px 0;
    box-shadow: 0 5px 5px -6px #6c6c6c;
}

#dashboard .logo-box img {
    max-width: 210px;
    width: 100%;
    display: block;
}

#dashboard .logo-box-1 {
    display: none;
}

#dashboard .secondary-sidebar-menu ul li.menu-divider {
    margin: 10px 0 10px 20px;
}

#dashboard .sidebar_bottom .secondary-sidebar-profile {
    display: flex;
    padding: 20px 0px 0px 20px;
}

#dashboard .secondary-sidebar-profile>a {
    display: flex;
    width: 50px;
    overflow: hidden;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    color: #646464;
    align-items: center;
}

#left-profile-name {
    color: white;
}

#left-profile-name img {
    filter: brightness(10) opacity(.65);
    width: 15px;
    margin-right: 2px;
    ;
}

#left-profile-name a {
    color: white;
    font-size: 0.8em;
    margin-right: 10px;
}

.collapsed-sidebar #dashboard .sidebar_bottom .secondary-sidebar-profile {
    padding: 20px 0px 10px 10px;
    flex-direction: column;
}

.collapsed-sidebar #left-profile-name a {
    text-align: center;
    display: block;
    margin: 10px 0px;
}

.collapsed-sidebar #left-profile-name span {
    display: none;
}

#dashboard .secondary-sidebar-profile>a:hover,
#dashboard .secondary-sidebar-profile>a:hover {
    color: #333;
}

#dashboard .secondary-sidebar-profile>a img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    float: left;
    object-fit: cover;
}

#dashboard .secondary-sidebar-profile>a p {
    padding: 0 0 0 20px;
    text-transform: capitalize;
    font-size: 16px;
    color: #BFE6E7;
}

#dashboard .secondary-sidebar-profile>a:hover {
    text-decoration: none;
}

#dashboard .secondary-sidebar-profile-menu {
    margin-left: 18px;
    overflow: hidden;
    border-top: 1px solid #dadde0;
    border-bottom: 1px solid #dadde0;
    display: none !important;
}

#dashboard .secondary-sidebar-profile-menu li {
    float: left;
}

#dashboard .secondary-sidebar-profile-menu li a {
    display: block;
    text-align: center;
    padding: 7px 0;
    color: #51595F;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

#dashboard .secondary-sidebar-profile-menu li a:hover {
    color: #59968A;
}

.collapsed-sidebar #dashboard .secondary-sidebar-profile>a p,
.collapsed-sidebar #dashboard .secondary-sidebar-profile>a i,
.collapsed-sidebar #dashboard .secondary-sidebar-profile-menu {
    display: none !important;
}

.collapsed-sidebar #dashboard .secondary-sidebar-profile>a img {
    width: 41px;
    height: 41px;
    margin: auto;
    display: block;
    float: none;
}

.collapsed-sidebar #dashboard .secondary-sidebar-menu ul li.menu-divider {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}

.mobile-sidebar {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-top: 2em;
}

.sidebar_bottom {
    width: 100%;
    height: 100px;
}

.secondary-sidebar-menu.menu-top {
    overflow: auto;
    width: 100%;
}

.sidebar_bottom .secondary-sidebar-menu {
    margin-top: 20px;
}

body:not(.collapsed-sidebar) #dashboard .secondary-sidebar-menu ul li li a {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.collapsed-sidebar #dashboard .secondary-sidebar-menu ul li li a {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.collapsed-sidebar #dashboard .secondary-sidebar-menu ul li li a:hover {
    padding-left: 24px !important;
    font-weight: bold;
    color: #3D3D3D !important;
}

#dashboard .btn-settings-toggle {
    margin: 5px 4px 10px 22px;
    width: calc(100% - 26px);
}

#dashboard .btn-settings-toggle i {
    display: none;
}

.collapsed-sidebar #dashboard .btn-settings-toggle {
    margin: 0;
    width: 100%;
    height: 45px;
    border-radius: 30px;
}

.collapsed-sidebar #dashbo ard .btn-settings-toggle i {
    display: block;
}

.collapsed-sidebar #dashboard .btn-settings-toggle span {
    display: none;
}

#dashboard .breadcrumb {
    padding: 0;
    margin: 15px 0 0;
    background: transparent;
}

#dashboard .breadcrumb .breadcrumb-item {
    font-size: 13px;
    font-weight: 300;
}

#dashboard .breadcrumb>.active {
    color: #23A5A7;
}

.filter_block .search_box {
    background: #FFFFFF;
    border-radius: 10px;
    height: 60px;
    padding: 15px 30px;
    display: flex;
    align-items: center;
}

.filter_block .search_box img {
    max-width: 22px;
    height: auto;
    width: 100%;
    margin-right: 25px;
}


.filter-box button {
    background: #FFFFFF;
    border-radius: 10px;
    height: 60px;
    border: 0;
    width: 100%;
    padding: 0 55px;
    position: relative;
    text-align: left;
}

#dashboard .filter-box .dropdown-menu {
    width: 100%;
}

.filter-box button img {
    position: absolute;
    left: 20px;
    top: 22px;
}

.filter-box button span {
    color: #7D9596;
}

.filter-box button:before {
    position: absolute;
    background-image: url(../../img/dropdown.png);
    content: "";
    height: 10px;
    width: 15px;
    right: 15px;
    background-repeat: no-repeat;
    top: 0;
    bottom: 0;
    margin: auto;
}

.filter_block button.export_btn {
    background: #FFFFFF;
    border-radius: 10px;
    height: 60px;
    border: 0;
    width: 100%;
    color: #7D9596;
}

.filter_block button.export_btn img {
    display: inline-block;
    margin-right: 10px;
    filter: opacity(50%);
    max-width: 22px;
}

.secondary-sidebar-menu.menu-top {
    overflow-y: auto;
    margin: 35px 0 0;
    padding-right: 10px;
}

.collapsed-sidebar .menu-top::-webkit-scrollbar {
    display: none;
}

.collapsed-sidebar .menu-top {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 0, 0, 0.4);
}

.scrollbar.verticle-scroll::-webkit-scrollbar {
    width: 3px;
}

.scrollbar.horizontal-scroll::-webkit-scrollbar {
    width: 100%;
    height: 6px;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.75);
    background-clip: padding-box;
    border: 0;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-track {
    background-color: rgb(0 0 0 / 40%);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.scrollbar::-moz-scrollbar-button,
.scrollbar::-webkit-scrollbar-button {
    display: none;
}

.scrollbar {
    scrollbar-face-color: rgba(255, 255, 255, 0.75);
    scrollbar-shadow-color: rgb(0 0 0 / 40%);
    scrollbar-highlight-color: rgba(255, 255, 255, 0.75);
}

.scrollbar {
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.75) rgb(0 0 0 / 40%);
}

.verticle-scroll {
    overflow-y: auto;
}

.collapsed-sidebar .sidebar_bottom {
    padding: 0 10px 0 0;
}

.collapsed-sidebar .secondary-sidebar-menu.menu-top {
    padding: 0;
    height: 470px;
}

.pagination {
    padding: 0;
    position: absolute;
    bottom: 15px;
}

.pagination .page-numbers {
    background: #FFFFFF;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    color: #232323;
    font-size: 14px;
    justify-content: center;
    margin: 0 5px;
    padding: 0 10px;
    font-weight: 400;
}

.pagination .page-numbers.prev,
.pagination .page-numbers.next {
    width: auto;
    font-size: 13px;
    white-space: nowrap;
}

.pagination .page-numbers:hover,
.pagination .page-numbers.current {
    background: #23A5A7;
    color: #fff;
    text-decoration: none;
}

/* Page Content */

#dashboard .page-header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    padding: 0px 0px 0px 280px;
    z-index: 1;
}

.collapsed-sidebar #dashboard .page-header {
    padding-left: 70px;
}

.page-header-fix {
    background: #f1f5f9;
    padding-top: 1em;
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 60px;
}

#main-wrapper{
    padding-top: 4em !important;
}

#collapsed-sidebar-toggle-button, #sidebar-toggle-button{
    cursor: pointer;
}

#dashboard .page-header .navbar {
    border-bottom: 0;
}

#dashboard .page-inner #main-wrapper {
    padding: 95px 0 60px;
    height: 100%;
    position: relative;
}

#dashboard .page-content {
    background: #f1f5f9;
    margin-left: 0;
    width: 100%;
}

#dashboard .page-header .navbar-nav>li>a:link {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

#dashboard .page-header .navbar-nav>li:last-child>a {
    padding-right: 0 !important;
}


.hidden-sidebar #dashboard .page-header-fix .secondary-sidebar-profile {
    margin-top: 0;
    position: relative;
    padding: 0;
}

.hidden-sidebar #dashboard .page-header-fix .profile_txt {
    display: none;
}


.hidden-sidebar #dashboard .page-header-fix .profile_box {
    width: 35px;
    height: 35px;
}

/* Components */

#dashboard .card {
    border: none;
    box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
    border-radius: 10px;
    margin: 15px 0;
}

#dashboard .btn-primary {
    border-color: #ffffff;
    background-color: #ffffff;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    color: #468074;
    width: 100%;
    margin: 0;
    border-radius: 30px;
}

#dashboard .dropdown-menu {
    border-radius: 10px;
    -webkit-box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
    box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
    padding: 15px;
    border: 0;
}

#dashboard .dropdown-menu .dropdown-notifications a {
    padding: 10px 10px 10px 30px;
    display: block;
    position: relative;
}

.dropdown-notifications .notification-badge {
    padding: 5px;
    position: absolute;
    left: 0;
    width: 25px;
    height: 25px;
    line-height: 18px;
    font-size: 14px;
}

#dashboard .dropdown-menu a:hover {
    background: #f8f9fa !important;
}

#dashboard .dropdown-menu.dropdown-menu-right a {
    color: #212529;
}

#dashboard .dropdown-menu.dropdown-menu-right .drop-title a {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
}

#dashboard .dropdown-menu.notification-dropdown {
    min-width: 325px;
}

#dashboard .dropdown-menu.profile-dropdown {
    min-width: 220px;
}

/* Responsive */

.hidden-sidebar #dashboard .navbar {
    padding: 0 !important;
}

.hidden-sidebar #dashboard .navbar-header {
    background: transparent;
    border: 0;
}

.hidden-sidebar #dashboard .navbar-header .logo-sm #sidebar-toggle-button {
    color: #AEB5C5 !important;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.hidden-sidebar #dashboard .navbar-header .logo-sm #sidebar-toggle-button:hover {
    color: #fff !important;
}

.hidden-sidebar #dashboard .navbar-header .logo-sm a {
    color: #AEB5C5;
}

.hidden-sidebar #dashboard .navbar-header .navbar-toggler {
    color: #AEB5C5 !important;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}




.hidden-sidebar #dashboard .page-header .navbar-nav>li>a {
    font-size: 14px;
}

.hidden-sidebar #dashboard .page-header .navbar_left .navbar-nav>li>a:hover,
.hidden-sidebar #dashboard .page-header .navbar_left .navbar-nav>li>a:active,
.hidden-sidebar #dashboard .page-header .navbar_left .navbar-nav>li>a:focus {
    color: #fff;
}

.hidden-sidebar #dashboard .page-header .navbar_right .navbar-nav>li>a:hover,
.hidden-sidebar #dashboard .page-header .navbar_right .navbar-nav>li>a:active,
.hidden-sidebar #dashboard .page-header .navbar_right .navbar-nav>li>a:focus {
    color: #333;
}

.hidden-sidebar:not(.collapsed-sidebar) #dashboard .secondary-sidebar {
    width: 275px;
}

.hidden-sidebar.page-sidebar-visible #dashboard .secondary-sidebar {
    margin-left: 0;
}

.hidden-sidebar #dashboard .secondary-sidebar .accordion-menu li a {
    padding-right: 0;
}



.hidden-sidebar #dashboard .secondary-sidebar-profile>a {
    padding-right: 18px;
    padding-top: 15px;
}

.hidden-sidebar #dashboard .secondary-sidebar-profile-menu {
    margin-right: 18px;
}

.hidden-sidebar #dashboard .secondary-sidebar-bar {
    display: none;
}


/* Other */

#dashboard .bg-primary {
    background-color: #59968A !important;
    color: #fff;
}

#dashboard .btn-outline-primary {
    color: #59968A;
    border-color: #59968A;
}

#dashboard .btn-outline-primary:hover {
    color: #fff;
    background-color: #59968A;
    border-color: #59968A;
}

#dashboard .btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(89, 150, 138, 0.5);
}

#dashboard .btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #59968A;
    background-color: transparent;
}

#dashboard .btn-outline-primary:not(:disabled):not(.disabled):active,
#dashboard .btn-outline-primary:not(:disabled):not(.disabled).active,
#dashboard .show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #59968A;
    border-color: #59968A;
}

#dashboard .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
#dashboard .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
#dashboard .show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(89, 150, 138, 0.5);
}

#dashboard .btn-primary:focus,
#dashboard .btn-primary.focus,
#dashboard .btn-primary:not(:disabled):not(.disabled):active:focus,
#dashboard .btn-primary:not(:disabled):not(.disabled).active:focus,
#dashboard .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(89, 150, 138, 0.5);
}

#dashboard .btn-primary:hover,
#dashboard .btn-primary.active,
#dashboard .btn-outline-primary.active,
#dashboard .btn-primary:active:hover,
#dashboard .btn-primary.active:hover,
#dashboard .show>.dropdown-toggle.btn-primary:hover,
#dashboard .btn-primary:active:focus,
#dashboard .btn-primary.active:focus,
#dashboard .show>.dropdown-toggle.btn-primary:focus,
#dashboard .btn-primary:active.focus,
#dashboard .btn-primary.active.focus,
#dashboard .show>.dropdown-toggle.btn-primary.focus,
#dashboard .btn-primary:focus,
#dashboard .btn-primary.focus {
    border-color: #468074 !important;
    background-color: #468074 !important;
}

#dashboard .mailbox-menu ul li a.active,
#dashboard .mailbox-menu ul li a:hover,
#dashboard .todo-menu ul li a:hover,
#dashboard .todo-menu ul li.active a {
    color: #59968A;
}


#dashboard .post-actions ul li a.liked {
    color: #ED5160;
}

.page-header-fixed #dashboard .page-header {
    left: 199px;
    width: calc(100% - 199px);
}

.page-header-fixed #dashboard .secondary-sidebar-bar {
    background: #3D3D3D !important;
}

.page-header-fixed.collapsed-sidebar #dashboard .page-header {
    left: 60px;
    width: calc(100% - 60px);
}

.page-sidebar-fixed #dashboard .secondary-sidebar-menu {
    padding-bottom: 223px;
}

#dashboard .badge.badge-primary {
    background: #59968A;
    color: #FFF;
}

#dashboard .badge.badge-primary:hover {
    background: #468074;
}

#dashboard .card-img-top {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

#dashboard .card-header:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

#dashboard .card-img {
    border-radius: 10px;
}

#dashboard .list-group-item.active {
    background: #59968A;
    border-color: #59968A;
}

#dashboard .nav li a.active,
#dashboard .nav li a:hover {
    color: #59968A;
}

#dashboard .nav-pills .nav-link.active,
#dashboard .nav-pills .show>.nav-link {
    background-color: #59968A;
}

#dashboard .pagination li a,
#dashboard .pagination li a:hover {
    color: #59968A;
}

#dashboard .pagination li.active a {
    background: #59968A !important;
    border-color: #59968A !important;
}

#dashboard .pagination li a:focus {
    box-shadow: 0 0 0 0.2rem rgba(89, 150, 138, 0.25);
}

#dashboard .progress-bar {
    background-color: #59968A;
}


#dashboard .custom-range:focus::-webkit-slider-thumb,
#dashboard .custom-range:focus::-moz-range-thumb,
#dashboard .custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(89, 150, 138, 0.25);
}

#dashboard .custom-range::-webkit-slider-thumb {
    background-color: #59968A;
}

#dashboard .custom-range::-webkit-slider-thumb:active {
    background-color: #c2fff3;
}

#dashboard .custom-range::-moz-range-thumb {
    background-color: #59968A;
}

#dashboard .custom-range::-moz-range-thumb:active {
    background-color: #c2fff3;
}

#dashboard .custom-range::-ms-thumb {
    background-color: #59968A;
}

#dashboard .custom-range::-ms-thumb:active {
    background-color: #c2fff3;
}

#dashboard .plupload_button {
    border-color: #59968A !important;
    background-color: #59968A !important;
}

#dashboard .plupload_button:hover {
    border-color: #468074 !important;
    background-color: #468074 !important;
}

#dashboard .select2-selection__choice,
#dashboard .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: #59968A !important;
}

#dashboard .help-menu ul li a.active,
#dashboard .help-menu ul li a.active:hover {
    color: #59968A;
}

.login {
    position: relative;
    width: 100%;
    /* background-image: url(../../img/login-bg-img.png); */
    padding: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    /* border-radius: 30px; */
    overflow: hidden;
}

.login:before {
    position: absolute;
    content: "";
    background: linear-gradient(106.29deg, #23A5A7 1.26%, rgba(35, 165, 167, 0.13) 60.45%, rgba(35, 165, 167, 0.0291319) 98.41%, rgba(35, 165, 167, 0.0260714) 98.42%, rgba(35, 165, 167, 0.0229066) 98.43%, rgba(35, 165, 167, 0.0196342) 98.44%, rgba(35, 165, 167, 0.0196342) 98.45%, rgba(35, 165, 167, 0) 101.57%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.login-content {
    background: #fff;
    padding: 0;
    margin: auto;
    flex: 0 0 25%;
    border-radius: 7px;
    position: relative;
    /* box-shadow: 0px 0px 6px #bbbbbb; */
    border: 1px solid #b0dfdf;
}

.login-bg {
    flex: 0 0 45%;
    max-width: 45%;
    position: relative;
}

.login-bg img {
    max-width: 450px;
    width: 100%;
    display: block;
    margin: auto;
}

.custom-checkbox .custom-control-label::after {
    width: 25px;
    height: 25px;
    top: 0;
}

#dashboard .custom-control-input~.custom-control-label::before {
    width: 25px;
    height: 25px;
    border: 1px solid rgba(196, 196, 196, 0.7);
    border-radius: 5px;
    top: 0;
}

#dashboard .custom-control-input:checked~.custom-control-label::after {
    background-image: url(../../img/check.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px;
}

#dashboard .custom-control-input:checked~.custom-control-label::before {
    background-color: #23A5A7;
    border-color: #23A5A7;
}

#dashboard .custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    border-color: #59968A;
    background-color: #59968A;
}

#dashboard .custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(89, 150, 138, 0.25);
}

#dashboard .custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #b8fff1;
}

#dashboard .custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #fff;
    background-color: #c2fff3;
    border-color: #c2fff3;
}

#dashboard .login-box .login-footer {
    margin: 25px 10px 20px;
}

#dashboard .login-box .login-body .form-control:focus {
    box-shadow: none;
}

#dashboard .error-bg {
    background: #59968A;
}

.acnt_txt p {
    font-size: 16px;
}

.acnt_txt p a {
    color: #23A5A7;
    font-weight: 600;
    display: block;
    font-size: 22px;
}

.login-logo img {
    max-width: 205px;
    width: 100%;
    display: block;
    margin: 0 auto;
}

#dashboard .error-info .error-text .error-header h3 {
    color: #59968A;
}

#dashboard .error-bg::after {
    opacity: 0.15;
}

#dashboard .search-form form,
#dashboard .search-form .input-group {
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
}

#dashboard .page-header .form-control {
    background: #ffffff;
    border-color: #ffffff;
}

#dashboard .search-form #close-search {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

#dashboard .popular-product-list ul li#popular-product1 span.product-color {
    background: #59968A;
    color: #fff;
}

#dashboard .popular-product-list ul li#popular-product2 span.product-color {
    background: #749188;
    color: #fff;
}

#dashboard .popular-product-list ul li#popular-product3 span.product-color {
    background: #a6e3d7;
    color: #646464;
}

#dashboard .popular-products canvas {
    margin-top: 5px;
}

#dashboard .popular-product-list {
    margin-top: 5px;
}

#dashboard .video_block .card {
    border-radius: 15px;
    box-shadow: 0px 6px 10px #eaeaea;
    background-color: #fff;
    padding: 20px;
}

#dashboard .video_block .card-title-1 {
    text-align: center;
    border-bottom: 2px solid;
    padding: 0 0 10px;
    font-size: 22px;
    font-weight: 600;
}

#dashboard .video_block .profile_box {
    width: 125px;
    height: 125px;
    margin: 20px auto;
    border-radius: 50%;
    background: #3d3d3d;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 54px;
    position: relative;
}

#dashboard .video_block .profile_box .status {
    position: absolute;
    right: 18px;
    bottom: 0;
    width: 20px;
    height: 20px;
    border-radius: 30px;
    background: #fff;
}

#dashboard .video_block .profile_box .status.online:before {
    background-color: #58AB68;
}

#dashboard .video_block .profile_box .status.offline:before {
    background-color: #7D9596;
    ;
}

#dashboard .video_block .profile_box img {
    width: 100%;
    display: block;
}

#dashboard .video_block .profile_box .status:before {
    position: absolute;
    background: #000;
    width: 13px;
    height: 13px;
    border-radius: 30px;
    bottom: 3px;
    right: 3px;
    content: "";
}

#dashboard .video_block .card-title {
    font-size: 22px;
    font-weight: 500;
    color: #244142;
    margin: 0;
}

#dashboard .video_block .card-body {
    text-align: center;
    padding: 0;
    margin: 10px 0 0;
}

#dashboard .video_block .card-subtitle {
    color: red;
    font-size: 14px;
    font-weight: bold;
    margin: 10px 0 5px;
}

#dashboard .video_block .video_call {
    font-size: 16px;
    font-weight: 500;
    color: #23A5A7;
    text-transform: uppercase;
    border: none;
    background: transparent;
    padding: 0;
}

.video_btns {
    margin: 15px 0 0;
}

.video_btns button.video:hover{
    background: #fb4a3d;
}


.video_btns button.video {
    color: #fff;
    background-color: #f73324;
    display: table;
    border-radius: 23px;
    padding: 9px 31px;
    font-size: 18px;
    font-weight: normal;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
    border: none;
}

.video_btns button.video img {
    filter: brightness(10);
    -webkit-filter: brightness(10);
}

.page-footer {
    color: #8B8B8B;
    font-weight: 400;
    text-align: center;
    padding: 10px;
    font-size: 13px;
}

#profile .field_text {
    font-size: 14px;
    font-weight: 500;
    color: #2196f3;
    letter-spacing: 0.5px;
    width: 100%;
    padding: 0;
}

#profile .t_op label.label-name {
    width: 100%;
}

.form_block {
    border: 1px solid #dfdfdf;
    padding: 0 15px;
    border-bottom: 0;
    border-right: 0;
}

.form_block .form-group {
    border-right: 1px solid #dfdfdf;
    margin: 0;
    border-bottom: 1px solid #dfdfdf;
    padding: 15px;
}

#profile .t_op .setting_form .input_ad {
    width: 100%;
}

.recommendation_block .dropdown .dropdown-toggle {
    background: #468074;
    color: #fff;
    border-radius: 30px;
    border: 0;
    padding: 10px 15px;
    font-weight: 500;
    display: inline-block;
}

.recommendation_block .dropdown .dropdown-menu a {
    color: #468074;
    font-weight: 500;
    font-size: 15px;
}

.recommendation_block .dropdown .dropdown-toggle:focus,
.recommendation_block .dropdown .dropdown-toggle:active {
    box-shadow: none;
    background: #468074;
}

.recommendation_block .dropdown .dropdown-toggle::after {
    position: relative;
    top: 2px;
    border-top: 0.4em solid;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
}

#dashboard .recommendation_block .card {
    overflow: auto;
}

.recommendation_block .action_btns {
    color: #A3D4D6;
}

.recommendation_block .action_btns span {
    display: inline-block;
    margin: 0 5px;
    height: 18px;
    width: 1px;
    background: #A3D4D6;
    vertical-align: middle;
}


#main-wrapper .table th:last-child,
#main-wrapper .table td:last-child {
    text-align: right;
}

#main-wrapper .table td img.profile {
    width: 45px;
    height: 45px;
    border-radius: 50px;
    display: inline-block;
    margin-right: 10px;
}

/** new css **/
#main-wrapper .table td {
    background: #FFFFFF;
    color: #232323;
}

#main-wrapper .table tr td {
    border: 1px solid transparent;
    transition: all 0.3s ease;
}

#main-wrapper .table tr:hover td {
    border-top-color: #23A5A7;
    border-style: solid;
    border-width: 1px;
    border-bottom-color: #23A5A7;
}

#main-wrapper .table tr:hover td:first-child {
    border-left-color: #23A5A7;
}

#main-wrapper .table tr:hover td:last-child {
    border-right-color: #23A5A7;
}

#main-wrapper .table td:first-child {
    border-radius: 20px 0px 0px 20px;
    color: #3B5999;
    font-weight: 600;
}

#main-wrapper .table td:last-child {
    border-radius: 0px 20px 20px 0px;
}

#main-wrapper .table th,
#main-wrapper .table td {
    padding: 0px 15px;
    height: 45px;
    display: table-cell;
    vertical-align: middle;
    border: 0;
}

#main-wrapper .table thead th {
    color: #7D9596;
    font-weight: 500;
}

#main-wrapper .table-wrap {
    position: relative;
    width: auto;
    max-width: 100%;
    overflow-x: auto;
    white-space: nowrap;
}

.scrollbar {
    scrollbar-face-color: rgba(255, 255, 255, .75);
    scrollbar-shadow-color: rgba(0, 0, 0, 40%);
    scrollbar-highlight-color: rgba(255, 255, 255, .75);
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, .75) rgba(0, 0, 0, 40%);
}

#main-wrapper table.table {
    width: 100%;
    max-width: 100%;
    border-width: 0;
    white-space: nowrap;
    border-collapse: separate;
    border-spacing: 0 10px;
    margin: 0;
}

main#dashboard {
    height: 100%;
}

.sidebar_left {
    height: 100%;
    background: #468074;
    padding: 15px;
}

.collapsed-sidebar .logo-box {
    display: none;
}

.collapsed-sidebar #dashboard .logo-box-1 {
    display: block;
    background: #fff;
    padding: 10px;
    border-radius: 30px;
    margin: 0 0 15px;
}

.collapsed-sidebar #dashboard .logo-box-1 img {
    width: 100%;
    display: block;
}

#dashboard .content-body {
    background: #FFFFFF;
    border-radius: 20px;
    overflow: hidden;
}

.table_wrap {
    overflow: auto;
}

#dashboard .secondary-sidebar-menu .btn:hover {
    color: #fff;
    background: rgb(4 109 88 / 58%) !important;
}

#sidebar-toggle-button-close {
    display: none;
}

.page-sidebar-visible #sidebar-toggle-button-close {
    display: block;
    width: 30px;
    height: 30px;
    margin: auto;
    background: #fff;
    border-radius: 30px;
    text-align: center;
    line-height: 33px;
}

.toggle_bar_mob {
    display: none;
}

.mob-logo {
    display: none;
}

.hidden-sidebar .toggle_bar_mob {
    display: block;
    padding-left: 15px;
}

.hidden-sidebar .mob-logo {
    display: block;
    margin-left: auto;
    padding-right: 15px;
}

.hidden-sidebar #dashboard .page-content {
    padding: 0;
}

.hidden-sidebar #dashboard .page-header {
    box-shadow: none;
    border-radius: 0;
    background: #000;
}

.toggle_bar_mob a {
    color: #fff;
}

.navbar_right {
    padding-right: 15px;
}

.hidden-sidebar #dashboard .logo-box {
    width: 40px;
    height: 40px;
    padding: 5px;
    border-radius: 10px;
}

.hidden-sidebar #dashboard .navbar {
    display: block;
}

.hidden-sidebar #dashboard .navbar .container-fluid {
    justify-content: unset;
}

.hidden-sidebar #dashboard .page-header .navbar_left .navbar-nav>li>a {
    padding: 10px 8px !important;
}

.hidden-sidebar .navbar_left {
    margin-left: 10px;
}

.hidden-sidebar .navbar_right {
    width: 100%;
    background: #fff;
}

.hidden-sidebar #dashboard .secondary-sidebar {
    padding: 10px 10px 10px 0;
}

.hidden-sidebar #dashboard .secondary-sidebar-menu ul li.menu-divider {
    margin: 0;
}

.hidden-sidebar #dashboard .page-header .navbar_left .navbar-nav>li>a:link {
    color: #fff;
}

.hidden-sidebar .navbar_right {
    padding-right: 0;
}

#contact {
    height: calc(100% - 80px);
}

#contact>div {
    height: 100%;
}

#contact .contact-right {
    background: #E3F3F3;
}

#contact .contact_lf_block {
    padding: 75px;
}

#contact .contact-box .textblock>span {
    font-weight: 500;
    color: #23A5A7;
}

#contact .contact-box {
    position: relative;
    padding: 0 0 0 50px;
    margin: 0 0 70px;
}

#contact .contact-box .icon-box {
    position: absolute;
    left: 0;
    top: -5px;
}

#contact .contact-box h3 {
    font-size: 26px;
    font-weight: 500;
    color: #244142;
    margin: 0 0 5px;
}

#contact .contact-box p {
    font-size: 18px;
    color: #000000;
    font-weight: 400;
}

#contact .contact_rt_block {
    padding: 55px;
}

#contact .contact_rt_block .contact-box h3 {
    margin: 5px 0;
}

#contact .contact-box h3 a {
    color: #244142;
}

#contact .contact-box h3 span {
    display: block;
    padding: 10px 0 0;
}

main .form-group .form-control {
    font-size: 16px;
    font-weight: 400;
    border: 1px solid rgba(196, 196, 196, 0.7);
    box-sizing: border-box;
    border-radius: 10px;
    height: 55px;
    color: #4a4a4a;
    width: 100%;
    padding: 0 15px;
    background: transparent;
}

.view_profile .form-group .form-control {
    font-size: 16px !important;
    border: 1px solid rgba(196, 196, 196, 0.7) !important;
    border-radius: 10px !important;
    height: 55px !important;
    padding: 0 15px !important;

}


main form label {
    font-size: 16px;
    font-weight: 500;
    color: #7D9596;
    display: block;
    margin: 0 0 10px;
}

#dashboard form label a {
    font-size: 16px;
    font-weight: 500;
    color: #7D9596;
}

.form-group.button-group button {
    border-radius: 10px;
    height: 65px;
    max-width: 210px;
    width: 100%;
    font-weight: 700;
    border: 1px solid;
    font-size: 16px;
}

.form-group.button-group {
    margin: 30px 0 0;
}

.form-group.button-group button.submit-btn {
    background: #23A5A7;
    border-color: transparent;
    color: #fff;
}

.form-group.button-group button.close-btn {
    border-color: rgba(35, 165, 167, 0.4);
    float: none;
    color: #23A5A7;
    opacity: 1;
    margin-left: 10px;
}

#setting {
    height: calc(100% - 80px);
    padding: 60px;
}

#setting .form-input {
    position: relative;
}

#setting .form-input>img {
    position: absolute;
    left: 20px;
    top: 20px;
}

#setting .form-input .form-control {
    padding: 0 85px 0 50px;
}

#setting .form-input span {
    position: absolute;
    right: 18px;
    top: 20px;
}

#setting .form-input span a {
    color: #23A5A7;
}

#setting .form-group.button-group {
    margin-top: 100px;
}

/** small css **/
small {
    position: relative;
    padding-left: 10px;
    text-transform: capitalize;
    font-size: 1.1em !important;
    font-weight: bold !important;
    margin: 3em 0 0.5em 0;
    display: block;
}

small:after {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 3px;
    background: #23A5A7;
    top: 0;
    bottom: 0;
    margin: auto;
}

.logo-img img {
    max-width: 210px;
    width: 100%;
    display: block;
}

.page-listing ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;
}

.page-listing ul li {
    position: relative;
    flex: 0 0 25%;
    max-width: 25%;
}

.page-listing ul li:before {
    position: absolute;
    width: 100%;
    content: "";
    height: 1px;
    border-bottom: 1px dashed #BFE6E7;
    top: 19px;
    left: 103px;
}

.page-listing ul li a {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.page-listing ul li a span {
    background: #BFE6E7;
    width: 39px;
    height: 39px;
    display: block;
    border-radius: 30px;
    position: relative;
    margin: 0 auto;
}

.page-listing ul li a span:before {
    position: absolute;
    content: url(../../img/not-fill.png);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 39px;
}

.page-listing ul li.current a span {
    background-color: #244142;
}

.page-listing ul li.current a span:before {
    content: url(../../img/listing-active.png);
}

.page-listing ul li.filled a span {
    background-color: #23A5A7;
}

.page-listing ul li.filled a label {
    color: #23A5A7;
}

.page-listing ul li.filled a span:before {
    content: url(../../img/listing-fill.png);
}

.page-listing ul li a label {
    color: #244142;
    font-weight: 600;
    width: 100%;
    margin: 15px 0 0;
    text-align: center;
}

.page-listing ul li:last-child:before {
    content: none;
}

.page-listing ul li.current a {
    pointer-events: none;
    text-align: center;
}

main .form-group .form-control.select {
    background-image: url(../../img/select-drop.png);
    background-repeat: no-repeat;
    background-position: 95% center;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.calendar-icon {
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 18px;
}

#steps {
    border-radius: 10px;
    overflow: hidden;
    padding: 30px 0;
}

#steps .ma_in {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0px 0px 7px #cbc6c6;
    max-width: 1150px;
    margin: auto;
    padding: 10px;
}

#steps .ma_in header {
    padding: 15px 30px;
    border-bottom: 1px solid #DEDEDE;
    width: 100%;
}

#steps .content_block {
    padding: 20px 100px 50px;
}

#steps .ma_in .heading {
    font-size: 27px;
    color: #000;
    margin: 0 0 20px 0;
    font-weight: 400;
    text-align: center;
}

#steps blockquote {
    background: #D1F7F8;
    border-radius: 10px;
    padding: 30px;
}

#steps blockquote p {
    color: #26A4A6;
    font-size: 14px;
    line-height: 21px;
}

#steps .form-group.button-group button.submit-btn {
    max-width: 305px;
}

/** register page css **/
.register_block {
    padding: 25px;
}

.top_sec {
    background: #21A2A8;
    border-radius: 22px;
    padding: 55px 50px;
    position: relative;
    min-height: 225px;
    margin: 1em;
    display: flex;
    justify-content: space-between;
}

.top_sec h1 {
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
    margin: 0;
    color: #FFFFFF;
}

.top_sec h1 span {
    font-weight: 700;
}

.register_img {
    max-width: 250px;
    width: 100%;
}

.register_img img {
    width: 100%;
}

.form_box {
    padding-top: 20px;
}

.form_box .form-title {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #4a4a4a;
    text-align: center;
    margin: 0;
    padding: 0 0 20px;
    position: relative;
}

.form_box .form-title:before {
    position: absolute;
    content: "";
    background: #BFE6E7;
    width: 130px;
    height: 2px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.register_block .custom-checkbox {
    height: 65px;
    display: flex;
    align-items: center;
}

.register_block .custom-checkbox label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #232323;
}

.register_block .custom-checkbox label:before {
    width: 22px;
    height: 22px;
    top: 0;
}

.register_block .custom-control-input:checked~.custom-control-label::before {
    border-color: #21a2a8;
    background-color: #21a2a8;
    box-shadow: none;
}

.register_block .custom-checkbox .custom-control-label::after {
    width: 22px;
    height: 22px;
    top: 0;
}

.register_block .form-group .form-control {
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
}

#main-wrapper .order_block.pricing_info {
    /* position: static; */
    /* margin: 0; */
    /* padding: 0; */
    /* max-width: 450px; */
    /* margin-left: auto; */
    top: 150px;
}

.pricing_left_bx {
    background: #fff;
    border-radius: 30px;
    padding: 20px 40px 40px 40px;
}

#main-wrapper #ques,
#main-wrapper #upload {
    padding: 25px 25px 40px;
    background: #FFFFFF;
    border-radius: 20px;
}

#main-wrapper #upload .file-upload,
#main-wrapper #upload .file-upload_1 {
    height: 320px;
}

/*#main-wrapper #upload .drag-text {
    display: block;
}
#main-wrapper #upload .drag-text h3 {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #23A5A7;
    margin-top: 25px;
}
#main-wrapper #upload .drag-text h3 span {
    border: 0;
    border-radius: 0;
    max-width: 100%;
    font-weight: 500;
    line-height: normal;
    display: block;
}*/
#main-wrapper #upload .upload_box .control,
#main-wrapper #upload .upload_box .control a {
    color: #244142;
    font-weight: 400;
}

#main-wrapper .upload_box .control input:checked~.control__indicator {
    background: #23A5A7;
}

body .date {
    position: relative;
}

body .datepicker td,
body .datepicker th {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 14px;
}

body .datepicker-dropdown {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

#main-wrapper #profile {

}

.profile-edit-btn {
    position: absolute;
    top: 100px;
    right: 40px;
}

#main-wrapper #profile .pr_ofile {
    padding-top: 80px;
}

#register .ma_in header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#register .login_btn button {
    background: #23A5A7;
    border-radius: 5px;
    border: 0;
    height: 45px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    display: inline-block;
    padding: 0 45px;
}

.login-content {
    min-width: 350px;
}

.hidden-sidebar .secondary-sidebar {
    top: 56px !important;
    height: 100% !important;
    overflow: auto !important;
}

.hidden-sidebar.show .secondary-sidebar {
    left: 100% !important;
}

#close-menu {
    display: none;
}

.hidden-sidebar #close-menu {
    display: block;
    position: absolute;
    color: white;
    top: 6px;
    left: 250px;
}

@media screen and (min-width: 1600px) {
    #dashboard .video_block .card {
        padding: 50px;
        top: -75px;
    }

    #dashboard .video_block .video_call {
        padding: 0px 40px 0 40px !important;
    }

    .secondary-sidebar-menu.menu-top {
        height: 500px;
        margin: 55px 0 0;
    }

    .login-bg img {
        max-width: 600px;
    }
}

@media screen and (min-width: 1100px) {
    .register_img{
        position: absolute;
        top: 30px;
        right: 30px;
        width: 200px;
    }
    .top_sec .content{
        max-width: 550px;
    }
}

@media screen and (max-width: 1199px) {
    .register_img {
        width: 250px;
    }

    .top_sec {
        min-height: auto;
    }

    .top_sec h1 {
        font-size: 34px;
        line-height: 48px;
    }

    #dashboard .video_block .card {
        padding: 25px;
    }

    .collapsed-sidebar .secondary-sidebar-menu.menu-top {
        height: 350px;
    }

    .login-bg img {
        max-width: 350px;
    }

    #dashboard .login-box .login-footer {
        margin: 30px 0 0;
    }

    #contact .contact_lf_block {
        padding: 55px;
    }

    #contact .contact_rt_block {
        padding: 35px;
    }

    #contact .contact-box h3 {
        font-size: 18px;
    }

    #contact .contact_rt_block {
        padding: 25px 15px;
    }

    #contact .contact-box {
        padding: 0 0 0 45px;
    }

    #steps .content_block {
        padding: 20px 0px 50px;
    }
}

@media screen and (max-width: 991px) {
    .top_sec {
        padding: 30px;
    }

    .register_img {
        max-width: 250px;
    }

    .top_sec h1 {
        font-size: 25px;
        line-height: 33px;
    }

    .register_img {
        max-width: 172px;
        top: 30px;
        right: 30px;
    }

    #dashboard #contact .address .card {
        min-height: auto;
    }

    .login-content {
        flex: 0 0 100%;
        max-width: 100%;
    }

    #setting .form-group.button-group {
        margin-top: 50px;
    }

    .login-content {
        min-width: 300px;
    }
}

@media screen and (max-width: 767px) {
    small {
        font-size: 20px;
    }

    #steps .ma_in header {
        padding: 15px;
    }

    #steps {
        padding: 15px;
    }

    .top_sec {
        padding: 20px 20px 0px 20px;
    }

    .register_block {
        padding: 20px 0;
    }

    .register_img {
        position: relative;
        margin: auto;
        max-width: 250px;
        right: 0;
    }

    .top_sec h1 {
        font-size: 23px;
        line-height: 30px;
        margin: 0 0 -9px;
        text-align: center;
    }

    .form_box {
        padding-top: 30px;
    }

    .register_block .custom-checkbox {
        height: 45px;
    }

    .register_block .form_box button {
        margin: 15px auto 0;
    }

    .hidden-sidebar #dashboard .page-header {
        position: static;
        padding: 55px 0 0 0;
        background: transparent;
    }

    .page-header-fix {
        background: transparent;
        padding: 0;
    }

    .hidden-sidebar #dashboard .page-header .mob-header {
        background: #000;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 11;
        padding: 0 15px;
    }

    .hidden-sidebar #dashboard .page-header .search-form {
        height: 56px;
    }

    #dashboard .page-container:not(.container) {
        height: auto;
    }

    .secondary-sidebar-menu.menu-top {
        height: 350px;
        margin: 15px 0 0;
    }

    #dashboard .secondary-sidebar-menu ul li a {
        font-size: 15px;
        padding: 10px 0 10px 60px;
    }

    #dashboard .secondary-sidebar-menu ul li a span,
    #dashboard .secondary-sidebar-profile>a img {
        width: 35px;
        height: 35px;
        top: 3px;
    }

    #dashboard .secondary-sidebar-menu ul li a span img {
        max-width: 15px;
    }

    #dashboard .secondary-sidebar-profile>a p {
        font-size: 15px;
    }

    #dashboard .page-header .navbar-nav>li:nth-child(2) {
        margin: 0;
    }

    #dashboard .page-header .navbar-nav>li>a>img {
        max-width: 18px;
        filter: brightness(10);
        -webkit-filter: brightness(10);
    }

    .hidden-sidebar .toggle_bar_mob img {
        filter: brightness(10);
        -webkit-filter: brightness(10);
    }

    #dashboard .breadcrumb {
        padding: 0 20px;
    }

    #dashboard .page-inner {
        height: auto;
        padding: 0;
    }

    #dashboard .page-inner #main-wrapper {
        height: auto;
        padding: 25px 20px 0;
    }

    #dashboard .page-title {
        font-size: 22px;
    }

    #profile .t_op label.label-name {
        width: 100%;
    }

    .page-sidebar-visible #sidebar-toggle-button-close {
        width: 25px;
        height: 25px;
        line-height: 28px;
        position: absolute;
        right: 10px;
        top: 15px;
        z-index: 99;
    }

    .page-sidebar-visible #sidebar-toggle-button-close:before,
    .page-sidebar-visible #sidebar-toggle-button-close:after {
        position: absolute;
        content: "";
        width: 2px;
        height: 14px;
        background-color: #21a2a8;
        top: 5px;
    }

    .page-sidebar-visible #sidebar-toggle-button-close:before {
        left: 12px;
        transform: rotate(45deg);
    }

    .page-sidebar-visible #sidebar-toggle-button-close:after {
        transform: rotate(-45deg);
        right: 11px;
    }

    .mobile-sidebar {
        margin: 60px 0 0;
    }

    .secondary-sidebar-menu.menu-top {
        height: auto;
        margin: 15px 0 0;
        overflow: hidden;
        padding: 0;
    }

    .sidebar_bottom {
        position: static;
        padding: 0;
    }

    #dashboard .secondary-sidebar-profile {
        display: block;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px 45px 0 15px;
    }

    .sidebar_bottom .secondary-sidebar-menu {
        margin-top: 0;
    }

    .hidden-sidebar #dashboard .secondary-sidebar-profile>a {
        padding: 0;
    }

    .pagination {
        padding: 15px 0;
        justify-content: center;
        position: static;
    }

    .page-footer {
        padding: 15px;
        position: static;
        text-align: center;
    }

    .login {
        padding: 15px;
    }

    .login-box .acnt_txt {
        margin: 0 0 15px;
    }

    .login-logo img {
        max-width: 225px;
    }

    #dashboard .login-box .login-body .forgot {
        text-align: left;
    }

    .login-content {
        padding: 0px;
    }

    #dashboard .login-box .login-footer {
        margin: 25px 10px 20px;
    }

    .login-footer p {
        text-align: left;
    }

    #dashboard .login-box .login-header h3 {
        margin: 30px 0 0px;
    }

    #contact .contact_lf_block {
        padding: 0 30px;
    }

    #contact .contact-box {
        margin: 25px 0;
    }

    #contact .contact-box p {
        font-size: 16px;
    }

    #contact .contact_rt_block {
        padding: 0 40px;
    }

    #contact {
        height: auto;
    }

    #setting {
        height: auto;
        padding: 25px;
    }

    .page-listing ul li {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .page-listing ul li:before {
        left: 50px;
    }

    .page-listing ul li a span,
    .page-listing ul li a span:before {
        width: 35px;
        height: 35px;
    }

    .page-listing ul li a label {
        font-size: 12px;
        font-weight: 500;
    }

    #steps .ma_in .heading {
        font-size: 16px;
    }

    .pricing_left_bx {
        padding: 5px 20px 20px;
    }

    #main-wrapper #ques,
    #main-wrapper #upload {
        padding: 5px 10px 20px;
    }

    #main-wrapper #upload .file-upload,
    #main-wrapper #upload .file-upload_1 {
        height: 250px;
    }

    #main-wrapper #upload .drag-text h3 {
        font-size: 18px;
    }

    #main-wrapper #profile .pr_ofile.edit_profile {
        padding-top: 30px;
    }

    #register .login_btn {
        margin-left: 15px;
    }

    #register .login_btn button {
        height: 40px;
        font-size: 16px;
        padding: 0 20px;
    }
}

input:read-only {
    color: #bbb !important;
    border-color: #eee !important;
}
.form-group.disabled label{
    color: #999;
}

/*** resets for mantine ***/
.mantine-InputWrapper-root label{
    margin: 0;
}

.mantine-InputWrapper-root{
    margin-bottom: 10px;;
}

.mantine-InputWrapper-root button:focus,
.mantine-InputWrapper-root input:focus{
    border: 1px solid #eee;
}

.mantine-Input-wrapper input:read-only{
    border: 1px solid #ced4da !important;
    color: #000 !important;
}

main form label{
    color: #212529 !important;
}

input.mantine-Checkbox-input:read-only{
    border: 1px solid #999 !important;
}

.plan-title{
    font-weight: bold;
    font-size: 18px;
    margin-left: 5px;
    margin-top: 2em;
    display: block;
    margin-bottom: 0.5em;
}

@media screen and (max-width: 768px) {
    .page-header-fix {
        background: #333 !important;
        padding-right: 10px !important;
        margin-top: 0;
        height: 56px;
    }
    #sidebar-toggle-button{
        display: flex;
        justify-content: space-between;
    }
    #sidebar-toggle-button .logo-box{
     background: transparent !important;   
    }
    #sidebar-toggle-button > img {
        width: 24px;
        height: 24px;
        margin-top: 6px;
    }
        .hidden-sidebar .toggle_bar_mob{
            margin-top: 5px;
        }
        .top_sec{
            flex-wrap: wrap;
            justify-content: center;
        }
}

.order-list-status{
    position: relative;
    bottom: 30px;
}
.order-list-status td {
    border:none !important;
    border-radius: 0 !important;
    padding-bottom: 2em !important;
    padding-left: 2em !important;
}
#main-wrapper .table tr.order-list-status:hover td {
    border:none;
    border-radius: none;
}
.order-list-status .mantine-Stepper-separator{
    flex: 0.05 !important;
    width: 40px;
}