.number-keypad {
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.75rem;
  background-color: #ffffff;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
.countrycode-input {
  padding: 0.5rem;
  border-top-left-radius: 0.75rem;
  font-size: 1.5rem;
  line-height: 2rem;
  width: 50px;
  border: none;
}
.number-input {
  display: flex;
  padding: 0rem;
  border-top-right-radius: 0.75rem;
  width: 100%;
  height: 4rem;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #000000;
  border: none;
}
.numBtn-wrapper {
  padding: 0.25rem;
  width: 33.333333%;
}
.num-btn {
  border-radius: 0.75rem;
  width: 100%;
  height: 3rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  color: #374151;
  background-color: #ffffff;
  border: none;
}
.num-btn:hover {
  background-color: #e2e8f0;
}
.keypad-actions {
  display: flex;
  width: 100%;
}
.call-btn{
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 0.75rem;
  width: 100%;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #ffffff;
  background-color: #10b981;
  transition: all 0.3 ease;
  cursor: pointer;
  height: 60px;
}
.call-btn:hover{
  transition: all 0.4 ease;
  background-color: #0e9065;
}
.remove-btn {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 0.75rem;
  width: 100%;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #ffffff;
  background-color: #ef4444;
  cursor: pointer;
  height: 60px;
}
.remove-btn:hover{
background-color: rgb(143, 9, 9);
}
.user-card {
  display: flex;
  padding: 0.75rem;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: 300px;
}
.user-card-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  background-color: #ffffff;
  width: 50px;
  height: 50px;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.hangup-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  background-color: #ef4444;
  width: 50px;
  height: 50px;
}

.incoming-call-wrapper {
  display: flex;
  padding: 0.75rem;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: 400px;
}

.incoming-action {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  color: #ffffff;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  cursor: pointer;
  width: 50px;
  height: 50px;
}
.incoming-action.pickup {
  background-color: #10b981;
}
.incoming-action.reject,
.incoming-action.hangup {
  background-color: #dc2626;
}
.incoming-action.reject:hover {
  background-color: #b91c1c;
}
.incoming-action.hangup:hover {
  background-color: #b91c1c;
}
.incoming-action.pickup:hover {
  background-color: #059669;
}
