.responseError {
  text-align: center;
}

.registerResponse {
  text-align: center;
}

.emailResponse {
  text-align: left;
}

.emailResponse {
  position: absolute;
  right: 50px;
  z-index: 9999;
  top: 50px;
}



.emailResponse .alert i {
  padding: 0 5px 0 0px;
  position: relative;
  top: 2px;
}

.emailResponse .alert {
  font-size: 17px;
  font-weight: 300;
}

/* Alert */
.alert {
  padding: 9px 19px;
  background-color: #ffcfcc;
  border: 1px solid #f44336;
  border-left: 9px solid #f44336;
  color: #f44336;
  opacity: 0.83;
  transition: opacity 0.6s;
  margin-bottom: 15px;
  border-radius: 6px;
}

/* Alert Success */
.alert.success {
  border: 1px solid #04AA6D;
  border-left: 9px solid #04AA6D;
  background-color: #a3ffdd;
  color: #04AA6D;
}

/* Alert Info */
.alert.info {
  border: 1px solid #2196F3;
  border-left: 9px solid #2196F3;
  background-color: #c3d9eb;
  color: #2196F3;
}

/* Alert Warning */
.alert.warning {
  border: 1px solid #ff9800;
  border-left: 9px solid #ff9800;
  background-color: #ffe1b5;
  color: #ff9800;
}

/* Closebtn */
.closebtn {
  padding-left: 15px;
  color: #f44336;
  font-weight: bold;
  float: right;
  line-height: 18px;
  cursor: pointer;
  transition: 0.5s;
  font-size: 23px;
}

/* Closebtn Success */
.closebtn.success {
  padding-left: 15px;
  color: #04AA6D;
  font-weight: bold;
  float: right;
  line-height: 18px;
  cursor: pointer;
  transition: 0.5s;
  font-size: 23px;
}

/* Closebtn Info */
.closebtn.info {
  padding-left: 15px;
  color: #2196F3;
  font-weight: bold;
  float: right;
  line-height: 18px;
  cursor: pointer;
  transition: 0.5s;
  font-size: 23px;
}

/* Closebtn Warning */
.closebtn.warning {
  padding-left: 15px;
  color: #ff9800;
  font-weight: bold;
  float: right;
  line-height: 18px;
  cursor: pointer;
  transition: 0.5s;
  font-size: 23px;
}

.closebtn:hover {
  transform: scale(1.3);
}

/* OTP preloader */
.preloaderBg {
  position: fixed;
  z-index: -0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  text-align: center;
}

.preloader {
  margin: auto;
  background: url(../img/redirecting.gif) no-repeat center;
  background-size: 1000px;
  width: 300px;
  height: 300px;
}


.preloader2 {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #f00;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  animation: spin 1s ease-in-out infinite;
  position: relative;
  margin: auto;
  top: -280px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* End OTP preloader */
.RegLoginButton {
  background: #23A5A7;
  border-radius: 5px;
  border: 0;
  max-width: 500px;
  width: 100%;
  height: 50px;
  margin: 8px 0 9px 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  display: block;
  padding: 12px 12px 12px 12px;
  text-decoration: none;
}

.RegLoginButton:hover {
  background: #2a8789;
  border-radius: 5px;
  border: 0;
  max-width: 500px;
  width: 100%;
  height: 50px;
  margin: 8px 0 9px 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  display: block;
  padding: 12px 12px 12px 12px;
  text-decoration: none;
}

.preloaderStop {
  display: none;
}

.forgotPasswordAlert {
  position: fixed !important;
}




/* start  Preloader  */

.loader {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  border: 10px solid orange;
  border-top-color: #002147;
  box-sizing: border-box;
  background: transparent;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  0% {
    transform: rotate(360deg);
  }
}

/*End  Preloader  */

.loaderProfilepage {
  margin-left: 45%;
}

/*  start upload page */
/*-------Start doc upload css -------*/
.hideUploadImageCss {
  display: none !important;
}

.showUploadImageCss {
  display: block !important;
}

.hideUploadImageCssRevert {
  display: '';
}

.showUploadImageCssRevert {
  display: none !important;
}

/*-------End doc upload css -------*/
/*-------Start Sign upload css -------*/
.hideSignUploadImageCss {
  display: none !important;
}

.showSignUploadImageCss {
  display: block !important;
}

.hideSignUploadImageCssRevert {
  display: '';
}

.showSignUploadImageCssRevert {
  display: none !important;
}

.otpmodalSHow {
  display: block !important;
  opacity: 100 !important;
  background: cadetblue;
}

/*-------End Sign upload css -------*/


/* end upload */

.HIdeELEment {
  display: none;
}



#pricing .coupon_box span.applied_coupom {
  background: #23a5a7;
  color: #fff;
  font-size: 13px;
  padding: 1px 10px 3px 10px;
  border-radius: 30px;
  position: relative;
  top: -40px;
  left: 20px;
}

#pricing .coupon_box button.close {
  position: relative;
  top: -22px;
  right: 70px;
  color: red;
  opacity: 1;
}

.modalClose {
  position: fixed;
  padding: 4px 9px 15px 267px;
  font-size: 25px;

}

.hideClassForElement {
  display: none !important;
}

.showClassForElement {
  display: block !important;
}

.showShippingModal {
  display: block !important;
  opacity: 1000 !important;
  background-color: #3cb7b4;

}

/* -----start checkbox payment shipping address css------------ */

.custom-control.custom-checkbox {
  margin-top: 20px;
}

.register_block .custom-checkbox {
  height: 65px;
  display: flex;
  align-items: center;
}

.custom-checkbox label:before {
  width: 22px;
  height: 22px;
  top: 0;
  background: #fff;
  box-shadow: none;
  border: 1px solid #0000002b !important;
}


.custom-checkbox label:before {
  width: 22px;
  height: 22px;
  top: 0;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #ffffff;
  background-color: #23a5a7;
  box-shadow: none;

}


.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: #23a5a700;
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgb(35 165 167 / 0%);
}

/* -----End checkbox payment shipping address css------------ */
.error {
  color: red;
}

.UpdateLoader {
  position: relative !important;
}

.EditFileCss {
  cursor: pointer
}

/* / preloader / */

.lds-ellipsis {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto 0 auto;
  display: flex;
  align-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #3a9fa3;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

/* / end preloader / */
/* start View recommendation modal */
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 99;
  height: 80vh;
  top: 50px !important;
  overflow-y: scroll;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: #0000 !important;
}

.ReactModal__Content.ReactModal__Content--after-open {
  transform: translate(-44%, -30%) !important;
}

time.form-control {
  line-height: 52px;
}


/* .list__icon {
  width: 53%;
  justify-content: center;
  margin: 0 auto;
  min-height: 30px;
} */
.list__icon {
  max-width: 1000px;
  justify-content: center;
  margin: 0 auto;
  min-height: 30px;
}

.list__icon.doc_vid {
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  min-height: 30px;
}

.list__icon .col-md-12>p {
  font-size: 17px;
  line-height: 26px;
  color: #707070;
}


.list__icon .list__bx {
  background: #fff;
  position: relative;
  padding: 5px 14px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 4px solid rgb(33 162 168 / 20%);
  display: flex;
  align-items: center;
  transition: background ease 0.8s;
  box-shadow: 0px 6px 10px rgb(205 206 206 / 49%);
}

/* .list__icon .list__bx {
  position: relative;
  padding: 16px 14px;
  border-radius: 5px;
  background-color: #202020;
  border: 4px solid rgb(33 162 168 / 20%);
  display: flex;
  align-items: center;
  min-height: 87px;
  transition: background ease 0.8s;
  cursor: pointer;
  box-shadow: 0px 6px 10px rgb(205 206 206 / 49%);
} */

.list__icon .list__bx img {
  filter: brightness(1.3);
}

.list__icon .list__bx:hover {
  background: #18969c;
  border: 4px solid rgb(206 253 255 / 56%);
  box-shadow: 0px 3px 13px rgb(33 162 168 / 62%);
}

.list__icon .list__bx:hover img {
  filter: brightness(10.5);
}

.list__icon .list__bx:hover p {
  color: #fff;
}

.list__icon .list__bx:hover a {
  color: #fff;
}

.list__text p a {
  color: #44c4cb;
  text-decoration: underline;
  font-weight: 500;
}

.list__text {
  width: 100%;
}

.list__bx img {
  float: left;
  margin-right: 11px;
  width: 35px;
}

.list__text p {
  font-size: 13px;
  font-weight: 500;
}





/* .icon__wrapper {
  background: #21a2a8;
  padding: 28px 30px;
  border-radius: 4px;
  box-shadow: 0px 6px 10px #dadada;
} */
.icon__wrapper.doc_vid_wrapper {
  background: #ffffff;
  padding: 28px 30px;
  box-shadow: 0px 6px 10px #dadada;
  width: 100%;
  margin: 0 auto;
  border-radius: 19px;
  height: auto;
}

.icon__wrapper {
  padding: 10px 30px;
  width: 100%;
  margin: 0px auto 30px auto;
  border-radius: 2px;
  height: auto;
}

.imgbox {
  position: relative;
  transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  -webkit-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
}

.imgbox:before {
  position: absolute;
  left: 7px;
  top: 10px;
  content: '';
  width: 30px;
  height: 30px;
  opacity: 0.15;
  transform: rotate(45deg);
  background-color: #21a2a8;
}


.list__bx:hover .imgbox {
  -webkit-transform: scale(-1) rotate(180deg);
  -moz-transform: scale(-1) rotate(180deg);
  -ms-transform: scale(-1) rotate(180deg);
  -o-transform: scale(-1) rotate(180deg);
  transform: scale(-1) rotate(180deg);
}


.list__bx:hover .imgbox:before {
  background-color: #ffffff;
}











@media only screen and (max-width: 767px) {

  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 99;
    height: 80vh;
    top: 50px !important;
    overflow-y: scroll;
    width: 90vw;
    margin: 0 auto;
  }

  .ReactModal__Content.ReactModal__Content--after-open {
    transform: translate(-34%, -20%) !important;
  }

  .ReactModal__Content.ReactModal__Content--after-open {
    transform: translate(-34%, -20%) !important;
  }

}

/* End  View recommendation modal*/
.UnavailableDoctor {
  font-size: 2rem;
  background: #21a2a8;
  color: #f8f8f8;
  padding: 40px 42px 41px 42px;
  border-radius: 35px;
  box-shadow: 1px 1px 14px rgb(188 188 188);
  height: 175px;
  position: relative;
  top: -100px;
}

.video_block2,
.video_block2 .row {
  height: 100%;
  align-items: center;
}

#dashboard .UnavailableDoctor .btn-primary {
  border-color: #ffffff;
  background-color: #ffffff;
  transition: all .2s ease-in-out;
  color: #21a2a8;
  width: 100%;
  margin: 0;
  border-radius: 30px;
}

#dashboard .UnavailableDoctor .btn.btn-primary:hover {
  border-color: #ffffff !important;
  background-color: #21a2a8 !important;
  transition: all .2s ease-in-out;
  color: #ffffff !important;
  width: 100%;
  margin: 0;
  border-radius: 30px;
}

.UnavailableDoctor h2 {
  font-size: 24px;
  margin-bottom: 7px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

/* start edit Page Profile pic */
.profile_picture:after {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
}

.profile_picture:hover:after {
  background: #00000052;
}

.EditFileCss:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  background: #000000a3;
  content: "";
  border-radius: 50px;
  z-index: -1;
}



label.EditFileCss {
  position: absolute;
  right: 67px;
  bottom: 0;
  padding: 12px;
  z-index: 9;
}

/* End edit Page Profile pic */
#dashboard .verifyRecBTn {
  background-color: #1c897d !important;
  color: #fff;
}

#dashboard .login-logo {
  box-shadow: none;
}

#dashboard .video_block .msgCenter {
  top: 140px !important;
  background-color: #21a2a8;
  color: #fff;
}


/* dropdown buttin view rec */
/* .dropdown span:before {
  background: #a3d4d6;
  border-radius: 30px;
  content: "";
  height: 8px;
  position: absolute;
  top: 0px;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  width: 8px;
  right: 11px;
}

.dropdown span:after {
  width: 8px;
  height: 8px;
  content: "";
  position: absolute;
  background: #a3d4d6;
  top: 0px;
  right: 22px;
  border-radius: 30px;
  text-align: end;
  
}

.recommendation_block .action_btns {
  cursor: pointer;
}

.recommendation_block .dropdown .dropdown-toggle {
  background: #a3d4d6;
  border: 0;
  border-radius: 30px;
  color: #fff;
  display: inline-block;
  width: 8px;
  height: 8px;
}

.recommendation_block .dropdown .dropdown-toggle {
  background: #a3d4d6;
  border: 0;
  border-radius: 30px;
  color: #fff;  
  display: inline-block;
  width: 8px;
  height: 8px;
}
.recommendation_block .dropdown .dropdown-toggle{
  padding: 0;
} */
.ellipsis_inner {
  position: absolute;
  width: 112%;
  height: 100%;
  margin: auto;
  display: flex;
  align-content: flex-end;
  align-items: center;
  z-index: 100000000;
  flex-wrap: nowrap;
  left: -50px;
  right: -50px;
  bottom: 0;
  top: 0;
  background: rgb(0 0 0 / 25%);
}

.video_block.video_block2 .lds-ellipsis div {
  background: #57f9ff;

}

.pointer-e {
  pointer-events: none;
}

#dashboard .video_block .card-title {
  font-size: 19px;
  font-weight: 500;
  color: #244142;
  margin: 0;
}

#dashboard .video_block .card {
  border-radius: 15px;
  box-shadow: 0px 0px 10px #f4f4f4;
  background-color: #fff;
  border: 4px solid #f3f3f3;
}

.profile_box span {
  border-radius: 50%;
  overflow: hidden;
  width: 125px;
  height: 125px;
  position: relative;
}

.profile_box span img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: auto !important;
  height: 100%;
  max-width: unset !important;
  left: 0;
  right: 0;
}

#dashboard .video_block .profile_box {
  margin: 0px auto 3px auto;
}

#dashboard .icon__wrapper .page-title {
  color: #fff !important;
}









.icon__wrapper.doc_vid_wrapper {
  background: none;
  padding: 0;
  box-shadow: none;
}

#dashboard .icon__wrapper.doc_vid_wrapper .video_block .card {
  background: rgb(236 254 255 / 67%);
  border: 4px solid #fff;
  box-shadow: 6px 1px 10px #e3e3e3;
}

/* #dashboard .page-inner #main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.main__wrapper {
  width: 100%;
}

.icon__wrapper.doc_vid_wrapper .UnavailableDoctor {
  top: 0;
  margin: auto;
}

@media screen and (min-width: 1600px) {
  #dashboard .icon__wrapper .video_block .card {
    padding: 25px 25px;
    top: 0;
    margin-top: 32px;
    margin-bottom: 0;
  }
}
.pendingRecAlert{
    position: fixed;
    top: 110px;
    right: 30px;
    z-index: 99999999999;
}