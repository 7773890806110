.chatbox-container {
  background-color: white;
  width: 350px;
  height: 500px;
  border-radius: 8px;
  position: fixed !important;
  right: 8px;
  bottom: 10px;
  z-index: 99999;
}
.chatbox-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.chatbox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.chatbox-userdata {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid #dddde5;
}
.chatbox-user {
  display: flex;
  align-items: center;
  gap: 8px;
}
.username {
  font-weight: 600;
  font-size: 13px;
}
.userdata {
  font-size: 11px;
  color: #71717a;
}
.no-messages {
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.no-messages h2 {
  color: #71717a;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}
.conversations-wrapper {
  height: 380px;
}

.message-input-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  border-top: 1px solid #dddde5;
  padding: 6px;
}

.message-input {
  resize: none;
  border-radius: 8px;
  font-size: 12px;
  min-height: 12px;
  width: 100%;
  outline: none;
}
.conversations {
  display: grid;
  gap: 6px;
  padding: 6px;
}

.sender-wrapper {
  display: flex;
  align-items: start;
  gap: 6px;
  justify-content: end;
  margin: 0.5rem 0;
}
.sender-message {
  max-width: 75%;
  border-radius: 12px;
  background-color: #228be6;
  padding: 8px 12px;
  color: white;
  font-size: 13px;
}

.reciever-wrapper {
  display: flex;
  align-items: start;
  gap: 6px;
  margin: 0.5rem 0;
}
.reciever-message {
  max-width: 75%;
  border-radius: 12px;
  background-color: #f4f4f5;
  padding: 8px 12px;
  font-size: 13px;
}
