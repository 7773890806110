
.find_modal {
    width: 100%;
    margin: 0 auto;
    padding: 35px 10px;
    background: #fff;
    border-radius: 7px;
    height: 100%;
    max-width: 500px;
    box-shadow: 0 0 7px #cbc6c6;
    

}

.find_modal h1 {
    font-size: 28px;
    text-transform: capitalize;
    font-weight: 600;
    margin: 17px 0;
    color: #6ba4b0;
    text-align: center;
}

.find_modal p {
    font-size: 15px!important;
    font-weight: 400;
    margin-bottom: 20px;
    color: #484848;
    text-align: center;
}

#search {
    font-weight: 400;
    border-radius: 10px;
    box-sizing: border-box;
    line-height: 21px;
    height: 55px;
    padding: 0 15px;
    width: 100%;
    border: 1px solid #ced4da;
    font-size: 15px;
    outline: none;
}

.find_modal button {
    background: #23a5a7;
    border: 0;
    border-radius: 5px;
    color: #fff;
    display: block;
    font-size: 18px;
    font-weight: 500;
    height: 50px;
    line-height: 27px;
    margin: 0;
    max-width: 500px;
    width: 100%;
}

.find_modal button:hover {
    /* background: linear-gradient(to left, rgb(26 103 73) 0, rgb(42 180 195) 100%); */
    background-color: #468074!important;
    border-color: #468074!important;

}
/* .input input{
    color: #484848;
} */
::placeholder{
    color: #4a4a4a;
}

section.find .custom-control.custom-checkbox {
    align-items: center;
    display: flex;
    height: 65px;
    margin-top: 20px;
}
section.find .custom-control-label {
    color: #232323;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    padding-left:10px;
    text-align: left;
}
section.find .custom-control-label:after {
    height: 22px !important;
    top: 0 !important;
    width: 22px !important;
}
section.find .custom-control-label:before {
    height: 22px !important;
    top: 0 !important;
    width: 22px !important;
    background: #fff;
    border: 1px solid #0000002b!important;
    box-shadow: none;
}
section.find .custom-control-input:checked~.custom-control-label:before {
    background-color: #21a2a8;
    border-color: #21a2a8;
    box-shadow: none;
}
input#exampleCheck1:focus, input#search:focus {
    box-shadow: none !important;
}
.mainwrapper .tablebx {
    width: 100%;
    margin: 0 auto;
    padding: 35px 30px;
    background: #fff;
    border-radius: 7px;
    height: 100%;
    max-width: 500px;
    box-shadow: 0 0 7px #cbc6c6;

}
.mainwrapper table#fees_table {
    text-align: left;
    margin-bottom: 0;
}
.mainwrapper table#fees_table tr:nth-of-type(odd) {
    background: #23a5a7;
    color: #fff;
}
.mainwrapper table#fees_table td, .mainwrapper table#fees_table th {
    border-color: #d8d8d8;
}
.mainwrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
}
.mainwrapper table#fees_table td {
    font-size: 12px;
    color: #131a1a;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.mainwrapper .content p {
    color: #404040;
    text-align: center !important;
    font-size: 12px;
}

.mainwrapper .error {
    color: #dc3545!important;
    width: 100%;
    text-align: left;
    padding-top: 3px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

.mainwrapper label#checkbox-error {
    position: absolute;
    bottom: -17px;
    left: 0;
}

.mainwrapper .error ~ label#checkbox-error ~ label {
    margin-bottom: 33px;
}
.mainwrapper:before {
    position: absolute;
    content: "";
    background: linear-gradient(106.29deg, #23A5A7 1.26%, rgba(35, 165, 167, 0.13) 60.45%, rgba(35, 165, 167, 0.0291319) 98.41%, rgba(35, 165, 167, 0.0260714) 98.42%, rgba(35, 165, 167, 0.0229066) 98.43%, rgba(35, 165, 167, 0.0196342) 98.44%, rgba(35, 165, 167, 0.0196342) 98.45%, rgba(35, 165, 167, 0) 101.57%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

section.find {
    position: relative;
    z-index: 222;
    width: 100%;
}
section.find .btn:hover {
    color: #ffff;
}
@media screen and (max-width:767px) {
    .find_modal button {
        font-size: 20px;
        margin-top: 10px;
    }

    .find_modal h1 {
        font-size: 27px;
    }

    .find_modal p {
        font-size: 12px;
    }

}
@media screen and (max-width:559px) {
    .find_modal h1 {
        font-size: 24px;
    }
    
    .mainwrapper .content p {
        font-size: 14px !important;
    }
    
    .find_modal {
        padding: 15px 1px;
    }
    
    label.custom-control-label {
        font-size: 14px;
    }
    
    .find_modal button {
        width: 90%;
        margin:  auto;
    }
    
    .row.find_modal img {
        width: 100;
    }
    
}