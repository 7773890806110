#main-wrapper.auto_created_patient .table tr td:first-child {
    color: #3B5999;
    font-weight: 600;
}

.gender {
    width: 44px;
    height: 18px;
    background: #EAF1FC;
    border-radius: 12.5px;
    font-weight: 500;
    font-size: 9px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gender.male {
    background: #EAF1FC;
    color: #0267FF;
}

.gender.female {
    background: #FCEEF0;
    color: #FF0000;
}

.hidden-sidebar #dashboard .page-header-fix .secondary-sidebar-profile {
    margin-top: 0;
    position: relative;
    padding: 0;
}

.hidden-sidebar #dashboard .page-header-fix .profile_box {
    width: 35px;
    height: 35px;
}

.hidden-sidebar #dashboard .page-header-fix .profile_box .status {
    border: 2px solid #000000;
}

.hidden-sidebar #dashboard .page-header-fix .profile_txt {
    display: none;
}

.patient_data {
    background: #fff;
    border-radius: 20px;
    padding: 60px 145px 60px 60px;
}

/*.update-profile #upload .box_out {
    border: 1px dashed rgba(196, 196, 196, 0.7);
}
.update-profile .drag-text span {
    border: 1px solid #000000;
    color: #000000;
}
.update-profile #upload .drag-text h3 {
    color: #000000;
}*/
#main-wrapper .update-profile #upload {
    padding: 0 15px;
}

#profile.update-profile .signature_img.form-control {
    padding: 5px;
}

#profile.update-profile .signature_img img {
    max-width: 100%;
    height: 100%;
}

.patient_info {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 25px 50px;
    border: 1px solid transparent;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    margin: 15px 0;
}

.patient_info:hover {
    border-color: #21a2a8;
}

.patient_info label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7D9596;
}

main .patient_info .form-group .form-control {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    padding: 0;
    height: auto;
    border: 0;
}

.patient_info .name {
    padding: 0 0 0 20px;
}

.patient_info .name h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    margin: 0;
    color: #232323;
}

.patient_info .name span.verified {
    width: 18px;
    height: 18px;
    border-radius: 30px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.patient_info .name span.verified {
    background: #72C806;
}

.manual_rec_lf {
    background: #FFFFFF;
    padding: 25px;
    border-radius: 20px;
    max-width: 430px;
    width: 100%;
}

.manual_rec_right {
    width: 100%;
    padding: 0 0 0 35px;
}

.manual_rec_lf .rec_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #23A5A7;
}

.manual_rec_lf .rec_title.disabled {
    border-top: 1px solid #BFE6E7;
    color: #3d3d3d;
}

.manual_rec_block {
    background: #FFFFFF;
    border-radius: 20px;
    height: 100%;
    padding: 30px;
}

.manual_rec_block .btn {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    color: #23A5A7;
    margin-left: auto;
    display: flex;
    align-items: center;
}

.manual_rec_block .btn img {
    max-width: 17px;
    margin-right: 10px;
}

.manual_rec_block label {
    font-weight: 500;
    font-size: 16px;
    line-height: 38px;
    color: #7D9596;
    display: block;
    margin: 0;
}

.manual_rec_block p {
    font-weight: 400;
    font-size: 16px;
    line-height: 38px;
    color: #232323;
    margin: 0;
}

.manual_rec_right textarea {
    font-weight: 500;
    font-size: 16px;
    color: #7D9596;
    border: 0;
    resize: none;
    height: 100px;
    background: #fff;
    width: 100%;
    border-radius: 20px;
    padding: 30px;
}

.manual_rec_right .comment_box p {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #3A4B4B;
    margin: 0;
}

.caregiver_declaration {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 30px;
}

.caregiver_declaration .btn {
    border: 0;
    padding: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #23A5A7;
}

.declaration_box {
    padding: 30px 0 0;
    display: none;
}

.extand_validity .form-group.button-group button {
    max-width: 100%;
    text-decoration: none;
}

.exemtion_box {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 50px;
}

.validity_box {
    border-top: 1px solid #BFE6E7;
    padding-top: 25px;
    margin-top: 35px;
}

.validity_box p {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #7D9596;
}

.validity_box label {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    margin: 0;
    color: #000;
    min-width: 330px;
}

.rec_btns .btn {
    height: 65px;
    border-radius: 10px;
    padding: 20px 25px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border: 2px solid #23A5A7;
}

.rec_btns .btn.view_rec {
    background: #23A5A7;
    color: #FFFFFF;
}

.rec_btns .btn.send_rec {
    color: #23A5A7;
    margin-left: 10px;
}

.validity_box p span {
    color: #23A5A7;
}

.limit_exp {
    margin-bottom: 1rem;
    background: #FAE1E1;
    border-radius: 14.5px;
    margin-left: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    padding: 0 15px;
    color: red;
}

.validity_box .revoke_btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #23A5A7;
    padding: 0;
}

.record_btns .btn {
    background: #23A5A7;
    border-radius: 10px;
    height: 60px;
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

#main-wrapper.patient_data_page .patient_table .table tr td:first-child {
    font-weight: 400;
    color: #000000;
    padding-left: 20px;
}

#main-wrapper.patient_data_page .patient_table .table tr th:first-child {
    padding-left: 20px;
}

#main-wrapper.patient_data_page .table tr th,
#main-wrapper.patient_data_page .table tr td {
    padding: 0 10px;
}

.patient_data_page .patient_status {
    background: #F0F5EC;
    border-radius: 12.5px;
    font-weight: 500;
    font-size: 9px;
    padding: 0 10px;
}

.patient_data_page .patient_status.approve {
    color: #72C806;
}

.patient_data_page .time {
    font-size: 11px;
}

.patient_data_page .time span {
    color: #6BB908;
    display: block;
}

.renewals .card-status {
    line-height: 18px;
    background: #EAF1FC;
    border-radius: 12.5px;
    text-align: center;
    font-weight: 500;
    font-size: 9px;
    color: #0267FF;
    padding: 0 12px;
    display: inline-block;
}

.renewals .expressdel_label {
    color: #3449B7;
}

.renewals .total_item_box {
    line-height: 18px;
    border-radius: 12.5px;
    padding: 0 11px;
    display: inline-block;
    font-weight: 500;
    font-size: 9px;
}

.renewals .renewal_item {
    background: #FFEFE0;
    color: #F58620;
}

.renewals .idcard_item {
    margin: 0 5px;
    background: #F5E9F4;
    color: #B01F90;
}

.renewals .expressdel_item {
    background: #EFF1FA;
    color: #3449B7;
}

.renewals .renewal_label {
    line-height: 18px;
    background: #EEEEEE;
    border-radius: 12.5px;
    padding: 0 11px;
    font-weight: 500;
    font-size: 9px;
}

.renewals .btn {
    font-weight: 400;
    font-size: 10px;
    height: 30px;
    background: #23A5A7;
    border-radius: 15.5px;
    color: #fff;
    padding: 0 20px;
}

#main-wrapper.renewals .table th,
#main-wrapper.renewals .table td {
    padding: 0 10px;
}

#main-wrapper.renewals .table th:first-child,
#main-wrapper.renewals .table td:first-child {
    padding-left: 20px;
}

#main-wrapper.renewals .table th:last-child,
#main-wrapper.renewals .table td:last-child {
    text-align: left;
}

.video_box {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 30px 40px;
    height: 100%;
}

#main-wrapper.patient_rec .content-header .page-title {
    padding-right: 20px;
}

.csv_btn .download_btn {
    height: 50px;
    background: #23A5A7;
    border-radius: 10px;
    padding: 0 50px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
}

.csv_btn .download_btn img {
    display: inline-block;
    margin-right: 5px;
}

#main-wrapper.patient_rec .content-header {
    justify-content: space-between;
    align-items: center;
}

.video_box table tr td {
    font-weight: 400;
    font-size: 16px;
    color: #232323;
    mix-blend-mode: normal;
    opacity: 0.69;
    padding: 10px 0;
}

.video_box table tr td:first-child {
    color: #7D9596;
    opacity: 1;
    font-weight: 500;
}

.video_box table tr td:last-child {
    width: 100%;
    max-width: 20%;
}

main .comment_area .form-control {
    height: 245px;
    resize: none;
    padding: 20px;
}

.btn_block .btn {
    height: 65px;
    border: 1px solid #23A5A7;
    border-radius: 10px;
    padding: 0 30px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #23A5A7;
    background: transparent;
    margin: 0 20px 20px 0;
}

.btn_block .btn.send_rec {
    background: #23A5A7;
    color: #fff;
}

.btn_block .btn.unapprove {
    border-color: #F91B1B;
    color: #F91B1B;
    margin-bottom: 0;
}

.video_box .link {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #23A5A7;
}

.video_room {
    height: 459px;
    left: 1044px;
    top: 269px;
    background: rgba(166, 160, 160, 0.2);
    border-radius: 10px;
    margin: 30px 0;
}

.video_box table {
    white-space: nowrap;
}

@media screen and (min-width: 1600px) {
    #dashboard .video_block .card {
        padding: 50px;
    }

    #dashboard .video_block .video_call {
        padding: 0px 40px 0 40px !important;
    }

    .secondary-sidebar-menu.menu-top {
        height: 500px;
        margin: 55px 0 0;
    }

    .login-bg img {
        max-width: 600px;
    }

    #main-wrapper.patient_data_page .patient_table table.table {
        white-space: normal;
    }
}

@media screen and (max-width: 1199px) {

    .top_sec {
        min-height: auto;
    }

    .top_sec h1 {
        font-size: 34px;
        line-height: 48px;
    }

    #dashboard .video_block .card {
        padding: 25px;
    }

    .collapsed-sidebar .secondary-sidebar-menu.menu-top {
        height: 350px;
    }

    .login-bg img {
        max-width: 350px;
    }

    #dashboard .login-box .login-footer {
        margin: 30px 0 0;
    }

    #contact .contact_lf_block {
        padding: 55px;
    }

    #contact .contact_rt_block {
        padding: 35px;
    }

    #contact .contact-box h3 {
        font-size: 18px;
    }

    #contact .contact_rt_block {
        padding: 25px 15px;
    }

    #contact .contact-box {
        padding: 0 0 0 45px;
    }

    #steps .content_block {
        padding: 20px 0px 50px;
    }

    #dashboard .patient_table .dropdown-menu.show {
        transform: translate3d(-75px, 7px, 0px) !important;
    }

    .patient_data {
        padding: 50px;
    }

    .rec_btns .btn {
        height: 55px;
        padding: 10px 20px;
    }

    .validity_box label {
        min-width: 270px;
    }

    .video_box table tr td:last-child {
        max-width: 18%;
    }
}

@media screen and (max-width: 991px) {
    .top_sec {
        padding: 30px;
    }

    .register_img {
        max-width: 250px;
    }

    .top_sec h1 {
        font-size: 30px;
        line-height: 48px;
    }

    .register_img {
        max-width: 250px;
        top: 30px;
        right: 30px;
    }

    #dashboard #contact .address .card {
        min-height: auto;
    }

    .login-content {
        flex: 0 0 100%;
        max-width: 100%;
    }

    #setting .form-group.button-group {
        margin-top: 50px;
    }

    .patient_data {
        padding: 35px;
    }

    .patient_info {
        padding: 15px;
    }

    .patient_info .pic img {
        max-width: 45px;
    }

    .patient_info .name {
        padding: 0 0 0 15px;
    }

    .manual_rec_lf {
        max-width: 100%;
    }

    .manual_rec_right {
        padding: 30px 0 0;
    }

    .rec_btns .btn {
        margin: 15px 0;
    }

    .video_box {
        padding: 25px;
    }

    .btn_block .btn {
        padding: 0 15px;
        margin: 0 10px 10px 0;
    }

    .video_box table tr td:last-child {
        padding-left: 35px;
    }
}

@media screen and (max-width: 767px) {
    small {
        font-size: 20px;
    }

    #steps .ma_in header {
        padding: 15px;
    }

    #steps {
        padding: 0px;
    }

    .register_block {
        padding: 20px 0;
    }

    .register_img {
        position: static;
        margin: auto;
    }

    .top_sec h1 {
        font-size: 24px;
        line-height: 38px;
        margin: 0 0 15px;
        text-align: center;
    }

    .form_box {
        padding-top: 0;
    }

    .register_block .custom-checkbox {
        height: 45px;
    }

    .register_block .form_box button {
        margin: 15px auto 0;
    }

    .hidden-sidebar #dashboard .page-header {
        position: static;
        padding: 55px 0 0 0;
        background: transparent;
    }

    .page-header-fix {
        background: transparent;
        padding: 0;
    }

    .hidden-sidebar #dashboard .page-header .mob-header {
        background: #000;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 11;
        padding: 0 15px;
    }

    .hidden-sidebar #dashboard .page-header .search-form {
        height: 56px;
    }

    #dashboard .page-container:not(.container) {
        height: auto;
    }

    .secondary-sidebar-menu.menu-top {
        height: 350px;
        margin: 15px 0 0;
    }

    #dashboard .secondary-sidebar-menu ul li a {
        font-size: 15px;
        padding: 10px 0 10px 60px;
    }

    #dashboard .secondary-sidebar-menu ul li a span,
    #dashboard .secondary-sidebar-profile>a img {
        width: 35px;
        height: 35px;
        top: 3px;
    }

    #dashboard .secondary-sidebar-menu ul li a span img {
        max-width: 15px;
    }

    #dashboard .secondary-sidebar-profile>a p {
        font-size: 15px;
    }

    #dashboard .page-header .navbar-nav>li:nth-child(2) {
        margin: 0;
    }

    #dashboard .page-header .navbar-nav>li>a>img {
        max-width: 18px;
        filter: brightness(10);
        -webkit-filter: brightness(10);
    }

    .hidden-sidebar .toggle_bar_mob img {
        filter: brightness(10);
        -webkit-filter: brightness(10);
    }

    #dashboard .breadcrumb {
        padding: 0 20px;
    }

    #dashboard .page-inner {
        height: auto;
        padding: 0;
    }

    #dashboard .page-inner #main-wrapper {
        height: auto;
        padding: 25px 20px 0;
    }

    #dashboard .page-title {
        font-size: 22px;
    }

    #profile .t_op label.label-name {
        width: 100%;
    }

    .page-sidebar-visible #sidebar-toggle-button-close {
        width: 25px;
        height: 25px;
        line-height: 28px;
        position: absolute;
        right: 10px;
        top: 15px;
        z-index: 99;
    }

    .page-sidebar-visible #sidebar-toggle-button-close:before,
    .page-sidebar-visible #sidebar-toggle-button-close:after {
        position: absolute;
        content: "";
        width: 2px;
        height: 14px;
        background-color: #21a2a8;
        top: 5px;
    }

    .page-sidebar-visible #sidebar-toggle-button-close:before {
        left: 12px;
        transform: rotate(45deg);
    }

    .page-sidebar-visible #sidebar-toggle-button-close:after {
        transform: rotate(-45deg);
        right: 11px;
    }

    .mobile-sidebar {
        margin: 60px 0 0;
    }

    .secondary-sidebar-menu.menu-top {
        height: auto;
        margin: 15px 0 0;
        overflow: hidden;
        padding: 0;
    }

    .sidebar_bottom {
        position: static;
        padding: 0;
    }

    #dashboard .secondary-sidebar-profile {
        display: block;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px 45px 0 15px;
    }

    .sidebar_bottom .secondary-sidebar-menu {
        margin-top: 0;
    }

    .hidden-sidebar #dashboard .secondary-sidebar-profile>a {
        padding: 0;
    }

    .pagination {
        padding: 15px 0;
        justify-content: center;
        position: static;
    }

    .page-footer {
        padding: 15px;
        position: static;
        text-align: center;
    }

    .login {

        padding: 15px;
    }

    .login-box .acnt_txt {
        margin: 0 0 15px;
    }

    .login-logo img {
        max-width: 225px;
    }

    #dashboard .login-box .login-body .forgot {
        text-align: left;
    }

    .login-content {
        padding: 20px;
    }

    #dashboard .login-box .login-footer {
        margin: 15px 0 0;
    }

    .login-footer p {
        text-align: left;
    }

    #dashboard .login-box .login-header h3 {
        margin: 30px 0 0px;
    }

    #contact .contact_lf_block {
        padding: 0 30px;
    }

    #contact .contact-box {
        margin: 25px 0;
    }

    #contact .contact-box p {
        font-size: 16px;
    }

    #contact .contact_rt_block {
        padding: 0 40px;
    }

    #contact {
        height: auto;
    }

    #setting {
        height: auto;
        padding: 25px;
    }

    .page-listing ul li {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .page-listing ul li:nth-child(2) {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .page-listing ul li a span,
    .page-listing ul li a span:before {
        width: 35px;
        height: 35px;
    }

    .page-listing ul li a label {
        font-size: 14px;
    }

    #steps .ma_in .heading {
        font-size: 24px;
    }

    .pricing_left_bx {
        padding: 5px 20px 20px;
    }

    #main-wrapper #ques,
    #main-wrapper #upload {
        padding: 5px 10px 20px;
    }

    #main-wrapper #upload .file-upload,
    #main-wrapper #upload .file-upload_1 {
        height: 250px;
    }

    #main-wrapper #upload .drag-text h3 {
        font-size: 18px;
    }

    #main-wrapper #profile .pr_ofile.edit_profile {
        padding-top: 30px;
    }

    #register .login_btn {
        margin-left: 15px;
    }

    #register .login_btn button {
        height: 40px;
        font-size: 16px;
        padding: 0 20px;
    }

    .exemtion_box {
        padding: 30px;
    }

    .rec_btns .btn {
        margin: 5px 0;
    }

    .rec_btns .btn.send_rec {
        margin-left: 0;
    }

    .validity_box p {
        font-size: 15px;
    }

    .limit_exp {
        margin-left: 10px;
        font-size: 15px;
    }

    .csv_btn .download_btn {
        height: 40px;
        padding: 0 25px;
    }
    #pricing{
        padding: 5px !important;
    }
}

@media screen and (max-width: 480px) {
    .btn_block .btn {
        width: 100%;
    }
}

button.btn.unapprove, button.btn.view_rec, button.btn.save_note, button.btn.send_rec {
    height: 40px;
    padding: 0 16px;
    font-size: 14px;
    float: left;
}


modal.right.fade.in .modal-dialog {
    right:0 !important;
    transform: translateX(-50%);
    }
    .modal.right .modal-content {
    height:100%;
    overflow:auto;
    border-radius:0;
    }
    .modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
    }
    .modal.right.fade.in .modal-dialog {
    transform: translateX(0%);
    }
    .modal.right.fade .modal-dialog {
    right: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
    }
        
    #view-rec-modal table {
        margin: 0 auto;
    }
    .revoke {
        background-color: #f1f1f1;
        padding: 10px;
        border: 2px solid #ff0909;
        position: relative;
    }
    .revoke:after {
        content: 'Revoked';
        font-size: 50px;
        left: 76px;
        position: absolute;
        top: 50px;
        color: #f00;
        opacity: 0.4;
        rotate: -12deg;
        z-index: 999;
    }
    .revoke p {
        color:red !important;
    }
    .revoke.mt-0{
        color:red;
    }


.header{
    border-left: 5px #23A5A7 solid;
    padding-left: 8px;
    font-size: 1.4em;
    margin: 1em 0px;
}
.wrapper{
    padding: 0 1em;
}
.totalcost{
    font-weight: bold;
    font-size: 1.4em;
}
.applied{
    border: 1px green solid
}