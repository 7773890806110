* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.pre-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
}

.pre-loader .shape {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-5em);
}

.pre-loader .shape .circle {
    width: 22px;
    height: 22px;
    background-color: #2ab4c3;
    border-radius: 50%;
    margin: 0px 5px;
    animation: bounce 0.6s linear infinite;
}

.pre-loader .shape .circle:nth-child(2) {
    background-color: #1a6749;
    animation-delay: 0.1s;
}

.pre-loader .shape .circle:nth-child(3) {
    animation-delay: 0.2s;
}

.pre-loader .shadow {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-5em);
}
.pre-loader .shadow .shape-shadow:nth-child(2) {
    animation-delay: 0.1s;
}

.pre-loader .shadow .shape-shadow:nth-child(3) {
    animation-delay: 0.2s;
}

@keyframes bounce {
    from,
    to {
        transform: translateY(0) scale(1, 1);
        animation-timing-function: ease-in;
    }
    45% {
        transform: translateY(5em) scale(1, 1);
        animation-timing-function: linear;
    }
    50% {
        transform: translateY(5em) scale(1.5, 0.5);
        animation-timing-function: linear;
    }
    100% {
        transform: translateY(5em) scale(1, 1);
        animation-timing-function: ease-out;
    }
}

@keyframes bounceShadow {
    from,
    to {
        transform: translateY(5em) scale(0.5);
        filter: blur(5px);
    }
    45% {
        transform: translateY(5em) scale(0.5);
    }
    50% {
        box-shadow: 20px 0 5px rgba(0, 0, 0, 0.1), -20px 0 5px rgba(0, 0, 0, 0.1);
    }
    100% {
        transform: translateY(5em) scale(0.5);
        box-shadow: unset;
    }
}