 iframe {
    border-top: 0px solid #000;
    box-shadow: 0px 0px 9px #ccc;
    border-radius: 9px;
    overflow: hidden;
 }

 .video-call-actions {
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding-top: 64px;
     max-width: 258px;
     margin: 0 auto;
 }

 .video-action-button {
     background-repeat: no-repeat;
     background-size: 24px;
     border: none;
     height: 48px;
     margin: 0 8px;
     box-shadow: var(--navigation-box-shadow);
     border-radius: 8px;
     width: 48px;
     cursor: pointer;
     outline: none;
     background-color: var(--button-bg);
 }

 .video-action-button.mic {
     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-mic-off' viewBox='0 0 24 24'%3E%3Cpath d='M1 1l22 22M9 9v3a3 3 0 005.12 2.12M15 9.34V4a3 3 0 00-5.94-.6'/%3E%3Cpath d='M17 16.95A7 7 0 015 12v-2m14 0v2a7 7 0 01-.11 1.23M12 19v4M8 23h8'/%3E%3C/svg%3E%0A");
     background-position: center;
 }

 .video-action-button.camera {
     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-camera-off' viewBox='0 0 24 24'%3E%3Cpath d='M1 1l22 22M21 21H3a2 2 0 01-2-2V8a2 2 0 012-2h3m3-3h6l2 3h4a2 2 0 012 2v9.34m-7.72-2.06a4 4 0 11-5.56-5.56'/%3E%3C/svg%3E%0A");
     background-position: center;
 }

 .video-action-button.maximize {
     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-maximize' viewBox='0 0 24 24'%3E%3Cpath d='M8 3H5a2 2 0 00-2 2v3m18 0V5a2 2 0 00-2-2h-3m0 18h3a2 2 0 002-2v-3M3 16v3a2 2 0 002 2h3'/%3E%3C/svg%3E%0A");
     background-position: center;
 }

 .video-action-button.endcall {
     color: #ff1932;
     width: auto;
     font-size: 14px;
     padding-left: 42px;
     padding-right: 12px;
     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ff1932' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-phone-missed'%3E%3Cline x1='23' y1='1' x2='17' y2='7'/%3E%3Cline x1='17' y1='1' x2='23' y2='7'/%3E%3Cpath d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z'/%3E%3C/svg%3E");
     background-size: 20px;
     background-position: center left 12px;
 }

 .video-action-button.magnifier {
     padding: 0 12px;
     display: flex;
     align-items: center;
     width: auto;
     flex-grow: 0;
     color: #2c303a;
 }

 .video-action-button.magnifier svg {
     width: 20px;
     flex-shrink: 0;
 }

 .video-action-button.magnifier span {
     display: block;
     margin: 0 16px;
 }

 .smlbx {
     position: absolute;
     top: 8px;
     left: 23px;
 }
 .video_block {
    text-align: center;
    padding: 0;
    margin: 0;
}
 @media screen and (max-width: 767px) {
     #dashboard .page-inner {
         position: relative;
         padding: 0 0px;
     }
 }