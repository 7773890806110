@font-face {
  font-family: 'poppins';
  font-style: normal;
  font-weight: normal;
  src: local('poppins'), url('Poppins-Regular.woff') format('woff');
}

body {
  margin: 0;
  font-family: poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
  color:rgb(0, 130, 128);
}

a:hover{

}

ul.navbar-nav li{
  list-style: none;
}
.collapsed-sidebar .profile-text{
  display: none;
}

@media screen and (max-width: 768px) {
  ul.navbar-nav{
    display: none;
  }
  .fl-top-menu{
    margin-top: 25px;
  }
}

.fl-top-menu{
  font-size: medium;
}

.react-datepicker__day--outside-month {
  visibility: hidden !important;
}

.clickable{cursor: pointer;}


.call-popup{
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
}

.question{
  position: relative;
  padding-left: 10px;
  margin: 3em 0 0.5em 0;
  display: block;
}
.question::after{
  content: '';
  position: absolute;
  left: 0;
  height: 100%;
  width: 3px;
  background: #23A5A7;
  top: 0;
  bottom: 0;
  margin: auto;
}

.mantine-Radio-radio:read-only{
  border: 1px solid #333 !important;
}
.mantine-InputWrapper-root label{
  padding-left: 6px !important;
}