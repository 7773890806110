
#upload .box_out {
    border: 1px dashed #23A5A7;
    box-sizing: border-box;
    border-radius: 10px;
}
#upload .box_out p {
  font-size: 13px;
  font-weight: 500;
  color: #6c899e;
  line-height: 24px;
  letter-spacing: 0.2px;
}
#upload input.input_ad {
  border-bottom: 1px solid #ccc;
  font-weight: 500;
  width: 40%;
  border: 1px solid #eaeaea;
  padding: 10px 10px 10px 10px;
  font-size: 13px;
  background: #fcfdfe;
  border-radius: 4px;
  height: 44px;
}
#upload label {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  color: #244142;
}
#upload label a{
	font-size: 16px;
    font-weight: 500;
	color: #7D9596;
}
/* file upload */
#upload .file-upload,
#upload .file-upload_1 {
    width: 100%;
    height: 255px;
}

/*#upload .file-upload-btn {
  width: 100%;
  margin: 0;
  color: #fff;
  background: #1FB264;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #15824B;
  transition: all .2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}
#upload .file-upload-btn:hover {
  background: #1AA059;
  color: #ffffff;
  transition: all .2s ease;
  cursor: pointer;
}
#upload .file-upload-btn:active {
  border: 0;
  transition: all .2s ease;
}*/
#upload .file-upload-content,
#upload .file-upload-content_1 {
    display: none;
    text-align: center;
    height: 100%;
}
#upload .file-upload-input,
#upload .file-upload-input_1 {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
#upload .image-upload-wrap,
#upload .image-upload-wrap_1 {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.upload_box p {
    color: #244142;
    font-size: 16px;
}
#upload .image-dropping,
#upload .image-dropping_1 {
  background-color: rgba(60, 183, 180, 0.1);
  border: 4px dashed #ccc;
}

#upload .drag-text,
#upload .drag-text_1 {
    text-align: center;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.drag-text span,
.drag-text_1 span {
    border: 1px solid #23A5A7;
    box-sizing: border-box;
    border-radius: 10px;
    line-height: 65px;
    max-width: 210px;
    width: 100%;
    font-weight: 700;
    color: #23A5A7;
}
#upload .drag-text h3,
#upload .drag-text_1 h3 {
    font-weight: 700;
    color: rgb(0 0 0 / 40%);
    font-size: 16px;
    margin: 0 0 0 20px;
}
#upload .upload_cont {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 15px;
}
#upload .file-upload-image,
#upload .file-upload-image_1 {
    width: 100%;
    max-width: 300px;
}
#upload .remove-image {
    margin: 0;
    color: #fff;
    background: #cd4535;
    border: none;
    padding: 10px;
    border-radius: 10px;
    outline: none;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
}
#upload .remove-image img {
    filter: brightness(10);
	-webkit-filter: brightness(10);
    margin-right: 10px;
}
.upload_box p.red {
    color: #FF0000;
}
#upload .upload_box .control {
    color: #FF0000;
    margin: 0;
}
.upload_box .control input:checked ~ .control__indicator {
    background: #ff0000;
}
/*signature*/

#upload .signature note {
  font-size: 13px;
  font-weight: 500;
  color: #6c899e;
  line-height: 18px;
  text-align: center;
  padding: 0 0 20px 0px;
}
#upload .signature img.sig {
  width: 150px;
  display: block;
  margin: 0 auto 15px auto;
}
#upload .signature .signature-pad {
    width: 100%;
    height: 265px;
    border: 1px solid rgba(196, 196, 196, 0.7);
    border-radius: 10px;
}
.upload_box .form-group {
    display: inline-block;
}


